import { useTheme } from "@emotion/react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { MdOutlineZoomOutMap } from "react-icons/md";
import { FooterHome, Header } from "../../components";
import { downloadImage, handleFullscreen } from "../../utils";
import { Helmet } from "react-helmet-async";
import MobileFooter from "../../components/MobileFooter";

const BaycRenders = () => {
  const [nftId, setNftId] = useState("1");
  const [loading, setLoading] = useState(false);
  const [pose, setPose] = useState("Pose 1");
  const { palette, ...theme } = useTheme();
  const imageRef = useRef(null);
  const isMobile = useMediaQuery("(max-width:599px)");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        marginInline: "auto",
        maxWidth: "500px",
        borderRadius: 1,

        boxShadow: 3,
        [theme.breakpoints.down("sm")]: {
          maxWidth: "100vw",
          width: "100vw",
          padding: 0,
        },
      }}
    >
      <Helmet>
        <title>DashBO X BoredCloset : ApeChain Renders</title>
        <meta
          name="description"
          content={"DashBO X BoredCloset : ApeChain Renders"}
        />
        <meta
          property="og:title"
          content="DashBO X BoredCloset : ApeChain Renders"
        />
        <meta
          property="og:description"
          content="DashBO X BoredCloset : ApeChain Renders."
        />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/3d-container/bayc/renderMetaImg.png"
        />
        <meta property="og:url" content="https://dashbo.xyz/render" />
      </Helmet>
      <Header withoutRight />
      <Box
        className="no-scroll"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "90vh",
          overflowY: "scroll",
          [theme.breakpoints.down("sm")]: {
            height: "84vh",
          },
        }}
      >
        <Box
          sx={{
            mt: "20%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            [theme.breakpoints.down("sm")]: {
              mt: "120px",
            },
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontFamily: "var(--font-f_r)",
              width: "80px",
              textAlign: "center",
              fontSize: "1vw",
              color: "#ffb800",
              [theme.breakpoints.down("sm")]: {
                fontSize: "14px",
              },
            }}
          >
            BAYC #
          </Typography>
          <TextField
            id="name"
            variant="outlined"
            placeholder="Enter NFT ID"
            fullWidth
            sx={{
              color: "#fff",
              width: "600px",
              fontFamily: "var(--font-i_r)",
              "& .MuiInputBase-input": { color: "#fff" },
              "& .MuiInputLabel-root": { color: "#fff" },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: palette.button.default,
                },
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderRadius: "0.7vw",
                borderColor: palette.button.default,
              },
              [theme.breakpoints.down("sm")]: {
                width: "250px",
                borderRadius: "14px",
              },
            }}
            value={nftId}
            onChange={(e) => {
              setLoading(true);
              setNftId(e.target.value);
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontFamily: "var(--font-f_r)",
              width: "80px",
              pl: 2,
              mr: 2,
              fontSize: "1vw",
              textAlign: "center",
              color: "#ffb800",
              [theme.breakpoints.down("sm")]: {
                fontSize: "14px",
                mr: "10px",
                pl: "30px",
              },
            }}
          >
            POSE
          </Typography>
          <FormControl
            sx={{
              [theme.breakpoints.down("sm")]: {
                pl: 2,
              },
            }}
            fullWidth
          >
            <Select
              value={pose}
              placeholder="Select Pose"
              onChange={(e) => {
                setLoading(true);
                setPose(e.target.value);
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: "#000", // Set dropdown background color to black
                    color: "#fff",
                  },
                },
              }}
              sx={{
                width: "600px",
                backgroundColor: "#000", // Apply the background color here as well
                color: "#fff",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "0.7vw",
                  borderColor: palette.button.default,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "0.7vw",
                  pl: 1,
                  borderColor: palette.button.default,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: palette.button.default,
                },
                "& .MuiSvgIcon-root": {
                  color: "#fff",
                },
                [theme.breakpoints.down("sm")]: {
                  width: "250px",
                  borderRadius: "14px",
                },
              }}
              defaultValue={pose}
            >
              <MenuItem
                sx={{
                  fontFamily: "var(--font-i_r)",
                  color: "#fff !important",
                  "&:hover": {
                    backgroundColor: "#FFB800 !important", // Change background color on hover
                    color: "#000 !important",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "rgba(0,0,0,0.8)", // Change background color when selected
                  },
                }}
                value="Pose 1"
              >
                Pose 1
              </MenuItem>
              <MenuItem
                sx={{
                  fontFamily: "var(--font-i_r)",
                  color: "#fff !important",
                  "&:hover": {
                    backgroundColor: "#FFB800 !important", // Change background color on hover
                    color: "#000 !important",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "rgba(0,0,0,0.8)", // Change background color when selected
                  },
                }}
                value="Pose 2"
              >
                Pose 2
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            width: "680px",
            background: "#212121",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            mt: 2,
            borderRadius: "0.7vw",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
              width: "80vw",
              borderRadius: "14px",
            },
          }}
        >
          {loading && (
            <Box
              sx={{
                p: "10px !important",
              }}
            >
              <Skeleton
                animation="wave"
                variant="rounded"
                width={isMobile ? 300 : 600}
                height={isMobile ? 300 : 600}
              />
            </Box>
          )}
          <img
            ref={imageRef}
            onLoad={(e) => {
              setLoading(false);
            }}
            src={`https://storage.googleapis.com/3d-container/bayc/renders/${
              pose === "Pose 1" ? "Closeup" : "Images"
            }/_${nftId}.png`}
            style={{
              display: loading ? "none" : "block",
              padding: "10px",
              borderRadius: isMobile ? "20px" : "1.2vw",
              width: "96%",
            }}
            alt="bayc-render"
          />
          <Box
            sx={{
              display: "flex",
              paddingBottom: "10px",
              flexDirection: "row",

              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "center",
              }}
              onClick={() => {
                handleFullscreen(imageRef, () => {});
              }}
            >
              <MdOutlineZoomOutMap color="#ffb800" size={20} />
              <Typography
                sx={{
                  fontWeight: "800",
                  fontFamily: "var(--font-f_r)",
                  ml: 1,
                  fontSize: "0.8w",
                  color: "#FFB800",
                }}
              >
                FULL SCREEN
              </Typography>
            </Box>
            <Box
              sx={{ width: "2px", mx: 1, height: "100%", background: "gray" }}
            />
            <Box
              onClick={() => {
                downloadImage(imageRef.current.src, "render");
              }}
              sx={{
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AiOutlineDownload color="#ffb800" size={20} />
              <Typography
                sx={{
                  fontWeight: "800",
                  fontFamily: "var(--font-f_r)",
                  ml: 1,
                  fontSize: "0.8w",
                  color: "#FFB800",
                }}
              >
                DOWNLOAD RENDER
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "100vw" }}>
        {isMobile ? <MobileFooter></MobileFooter> : <FooterHome />}
      </Box>
    </Box>
  );
};

export default BaycRenders;
