import React, { useEffect, useState } from "react";
import loader from "../../assets/images/videoLoader.gif";
import { useTheme } from "@emotion/react";
import { Box, Dialog, LinearProgress, Typography } from "@mui/material";
import { TypingEffect } from "./TypingText";
export const ConvertingVideoModal = ({ open, duration, downloadType }) => {
  const { palette } = useTheme();
  const [loadingName, setLoadingName] = useState(
    "Gathering intergalactic building blocks..."
  );
  const [progress, setProgress] = useState(0);
  const getTime = () => {
    switch (downloadType) {
      case "LowRes":
        if (duration <= 10) {
          return 25;
        }
        if (duration > 10 && duration <= 20) {
          return 50;
        }
        if (duration > 20) {
          return 1.4;
        }
      case "HighRes":
        if (duration <= 10) {
          return 40;
        }
        if (duration > 10 && duration <= 20) {
          return 60;
        }
        if (duration > 20) {
          return 1.5;
        }
      case "UltraHD":
        if (duration <= 10) {
          return 2.1;
        }
        if (duration > 10 && duration <= 20) {
          return 4.1;
        }
        if (duration > 20) {
          return 6.1;
        }
      default:
        return 0;
    }
  };
  const getTimeUnit = () => {
    switch (downloadType) {
      case "LowRes":
        if (duration <= 10) {
          return "seconds";
        }
        if (duration > 10 && duration <= 20) {
          return "seconds";
        }
        if (duration > 20) {
          return "minutes";
        }
      case "HighRes":
        if (duration <= 10) {
          return "seconds";
        }
        if (duration > 10 && duration <= 20) {
          return "seconds";
        }
        if (duration > 20) {
          return "minutes";
        }
      case "UltraHD":
        if (duration <= 10) {
          return "minutes";
        }
        if (duration > 10 && duration <= 20) {
          return "minutes";
        }
        if (duration > 20) {
          return "minutes";
        }
    }
  };
  const totalProgress = () => {
    if (getTimeUnit() === "minutes") {
      return getTime() * 60;
    } else {
      return getTime();
    }
  };
  const maxTime = totalProgress();
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= maxTime) {
          clearInterval(interval); // Stop the interval when the max time is reached
          return maxTime;
        }
        return prevProgress + 1; // Increment the progress by 1
      });
    }, 1000); // 1000ms interval equals 1 second

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [maxTime]);
  useEffect(() => {
    let percentProgress = (progress / maxTime) * 100;
    if (percentProgress > 0 && percentProgress < 10) {
      setLoadingName(
        "Counting polygons, one by one (seriously, there are a lot)..."
      );
    }
    if (percentProgress > 10 && percentProgress < 20) {
      setLoadingName("Warming up our virtual 3D printing press...");
    }
    if (percentProgress > 20 && percentProgress < 30) {
      setLoadingName("Summoning the 3D gnomes to do their thing...");
    }
    if (percentProgress > 30 && percentProgress < 40) {
      setLoadingName(
        "Rounding up all the lost 3D sheep... just kidding, it's code!"
      );
    }
    if (percentProgress > 40 && percentProgress < 50) {
      setLoadingName("Holding back impatient 3D squirrels - they're nuts!");
    }
    if (percentProgress > 50 && percentProgress < 60) {
      setLoadingName(
        "Counting polygons, one by one (seriously, there are a lot)..."
      );
    }
    if (percentProgress > 60 && percentProgress < 70) {
      setLoadingName("Warming up our virtual 3D printing press...");
    }
    if (percentProgress > 70 && percentProgress < 80) {
      setLoadingName("Summoning the 3D gnomes to do their thing...");
    }
    if (percentProgress > 80 && percentProgress < 90) {
      setLoadingName(
        "Rounding up all the lost 3D sheep... just kidding, it's code!"
      );
    }
    if (percentProgress > 90 && percentProgress < 100) {
      setLoadingName("Holding back impatient 3D squirrels - they're nuts!");
    }
  }, [progress]);
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        disableEnforceFocus
        slotProps={{
          backdrop: {
            style: {
              zIndex: 2,
              cursor: "pointer",
              backgroundColor: "#000",
              opacity: "0.7",
            },
          },
        }}
        PaperProps={{
          style: {
            zIndex: 10,
            height: "40%",
            backgroundColor: "#000",
            border: `1px solid ${palette.button.default}`,
            borderRadius: "20px",
          },
        }}
        onClose={() => {}}
        open={open}
      >
        <Box
          p={3}
          display="flex"
          justifyContent="center"
          height="100%"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontFamily: "var(--font-g)",
              fontSize: "1.2rem",
              textAlign: "center",
              color: "#fff",
              mb: 2,
            }}
          >
            Loading...
          </Typography>
          <Box
            justifyContent="center"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
          >
            {/* <img src={loader} /> */}
            <LinearProgress
              variant="determinate"
              value={(progress / maxTime) * 100}
              sx={{
                width: "100%",
                height: "20px",
                borderRadius: "10px",
                backgroundColor: "#000",
                border: "3px solid #ffb800",
              }}
            />
            <TypingEffect text={loadingName} />
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};
