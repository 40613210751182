import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Box, Typography, useMediaQuery } from "@mui/material";
import { QRCode } from "react-qrcode-logo";
import { copyToClipboard } from "../../../utils";
import { getNftById } from "../../../services/clientService";
const UnityComponent = ({ flag, animation }) => {
  const { nftId, id } = useParams();
  const animate = animation
    ? animation?.type?.toLowerCase() + "@" + animation?.name
    : "";
  const [nftImage, setNFTImage] = useState("");
  const [url, setUrl] = useState(
    id === "boredapeyachtclub" || id === "mutant-ape-yacht-club"
      ? "https://storage.googleapis.com/3d-container/bayc/AR/ARFinal.html?collection=" +
          id +
          "&nftId=" +
          nftId +
          "&product=" +
          animate
      : "https://storage.googleapis.com/3d-container/AR/AR/index.html?nftId=" +
          nftId +
          "&animation=" +
          animate
  );
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  useEffect(() => {
    const fetchQrCode = async () => {
      try {
        const res = await getNftById(nftId);
        if (res) {
          setNFTImage(res?.nftImage?.image);
        } else {
          throw new Error(
            `Failed to fetch QR code. Status: ${res.error?.response?.status}`
          );
        }
      } catch (error) {
        console.error("Error fetching QR code:", error);
      }
    };
    fetchQrCode();
  }, [nftId]);
  return (
    <Container>
      {!flag ? (
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            border: "1px solid #FFB800",
            borderRadius: "0.5vw",
            width: { xl: "65%", sm: "100%", xs: "100%", lg: "44%", md: "50%" },
            marginInline: "auto",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center", // Center content vertically
          }}
        >
          <Box sx={{ padding: "8px" }}>
            <img
              src="https://storage.googleapis.com/3d-container/arCube.png"
              alt="AR Cube"
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </Box>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "var(--font-f_r)",
              fontSize: isMobile ? "14px" : "16px",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0em",
              color: "#C4C4C4",
              mb: isMobile ? 2 : 3,
            }}
          >
            "Unlocking Augmented Reality: Scan QR Code for <br></br>an
            Interactive Experience!"
          </Typography>
          <Box>
            <QRCode
              value={url}
              logoWidth={isMobile ? "200" : "250"}
              logoHeight={isMobile ? "200" : "250"}
              quietZone={4}
              bgColor="#FFFFFF"
              qrStyle="dots"
              logoOpacity={0}
              size={isMobile ? "200" : "250"}
              logoImage={nftImage}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
              marginInline: "auto",
              borderRadius: "4px",
              backgroundColor: "#242424",
              cursor: "pointer",
              alignItems: "center",
              justifyContent: "center",
              margin: "2vw",
            }}
            onClick={() => {
              copyToClipboard(url);
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontFamily: "var(--font-i_r)",
                fontSize: {
                  xl: "0.7em",
                  lg: "0.7em",
                  md: "18px",
                  xs: "0.7em",
                  sm: "0.7em",
                },
                alignSelf: "start",
                marginBlock: "0.5vw",
                color: "#C4C4C4",
              }}
            >
              {url}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            borderRadius: "0.5vw",
            width: "100%",
            marginInline: "auto",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center", // Center content vertically
          }}
        >
          <Box sx={{ padding: "8px" }}>
            <img
              src="https://storage.googleapis.com/3d-container/arCube.png"
              alt="AR Cube"
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </Box>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "var(--font-f_r)",
              fontSize: isMobile ? "14px" : "16px",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0em",
              color: "#C4C4C4",
              mb: isMobile ? 2 : 3,
            }}
          >
            "Unlocking Augmented Reality: Scan QR Code for <br></br>an
            Interactive Experience!"
          </Typography>
          <Box>
            <QRCode
              value={url}
              logoWidth={isMobile ? "200" : "250"}
              logoHeight={isMobile ? "200" : "250"}
              quietZone="4"
              bgColor="#FFFFFF"
              qrStyle="dots"
              logoOpacity="0.3"
              size={isMobile ? "200" : "250"}
              logoImage={nftImage}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
              marginInline: "auto",
              borderRadius: "4px",
              backgroundColor: "#242424",
              cursor: "pointer",
              alignItems: "center",
              justifyContent: "center",
              margin: "2vw",
            }}
            onClick={() => {
              copyToClipboard(url);
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontFamily: "var(--font-i_r)",
                fontSize: {
                  xl: "0.8em",
                  lg: "0.8em",
                  md: "14px",
                  xs: "10px",
                  sm: "12px",
                },
                alignSelf: "start",
                marginBlock: "0.5vw",
                color: "#C4C4C4",
              }}
            >
              {url}
            </Typography>
          </Box>
        </Box>
      )}
    </Container>
  );
};
export default UnityComponent;
