import { useTheme } from "@emotion/react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";

import "../../../components/Loader.css";
import "../../../components/buttons/styles.css";

import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useUnityContext } from "react-unity-webgl";
import { getModelData } from "../../../services/modelService";
import {
  setModelAnimations,
  setModelEnv,
  setModelExpressions,
  setModelMBA,
  setModelPoses,
} from "../../../slice/3dModel.slice";
import DashboardLayout from "../layout";

import { FaChevronLeft } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { decreaseBalance } from "../../../slice/balanceSlice";
import UnityComponent from "../../3dModels/ARUnity/UnityComponent";
import { Accordion, AccordionDetails, AccordionSummary } from "../../Accordion";
import LoadingOverlay from "../../LoadingOverlay";
import { ProjectButton } from "./ProjectButton";
import { ApeUnity } from "./UnityModel";
import { UnityUIScenes } from "./UnityUIScenes";
import { SlReload } from "react-icons/sl";
import { allEyes, allFaces } from "../../../utils";
import TooltipCustom from "./ToolTip";

function Dashboard3d() {
  const { palette, ...theme } = useTheme();
  const dispatch = useDispatch();
  const { id, nftId } = useParams();
  const [loader, setLoader] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [librayImg, setLibrayImg] = useState();

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const fetchData = async () => {
      setLoader(true);
      try {
        const data = await getModelData(
          id == "boredapeyachtclub" || id === "mutant-ape-yacht-club"
            ? 3155
            : nftId,
          signal,
          id
        );
        if (data?.nft?.id) {
          setLibrayImg(data?.nft?.image);

          if (id === "boredapeyachtclub" || id === "mutant-ape-yacht-club") {
            dispatch(
              setModelExpressions(
                data?.expression?.map((ani, i) => ({
                  ...ani,
                  selected: i === 0,
                }))
              )
            );
            dispatch(
              setModelMBA(
                data?.mba?.map((ani, i) => ({
                  ...ani,
                  selected: i === 0,
                }))
              )
            );
          }
          dispatch(
            setModelAnimations(
              id === "boredapeyachtclub" || id === "mutant-ape-yacht-club"
                ? data?.new_animation
                    ?.filter((e) => !e["poses"])
                    ?.map((ani, i) => ({
                      ...ani,
                      selected: i === 2,
                    }))
                : [
                    {
                      others: data?.animation?.map((ani, i) => ({
                        ...ani,
                        selected: i === 0,
                      })),
                      selected: true,
                    },
                  ]
            )
          );

          dispatch(
            setModelPoses(
              data?.new_pose?.map((ani, i) => ({
                ...ani,
                selected: i === 0,
              }))
            )
          );
          dispatch(
            setModelEnv(
              data?.new_env?.map((ani, i) => ({
                ...ani,
                selected: i === 0,
              }))
            )
          );
          setDataLoaded(true);
        }
      } catch (error) {
        console.error("Error fetching model data:", error);
      } finally {
        setLoader(false);
      }
    };
    fetchData();
    return () => {
      abortController.abort();
    };
  }, [dispatch]);
  const LibraryData = ({ title }) => {
    return (
      <Box container>
        <Typography
          sx={{
            fontWeight: "800",
            fontFamily: "var(--font-f_r)",
            fontSize: {
              xl: "2em",
              lg: "1.2em",
              md: "18px",
              xs: "0.8em",
              sm: "0.8em",
            },
            textTransform: "capitalize",
            color: "#C4C4C4",
          }}
        >
          {title}
        </Typography>
        <Box
          item
          sx={{
            margin: 1,
            width: { xl: "8vw", lg: "8vw", md: "9vw", xs: "auto" },
            height: "auto",
            border: "1px solid #696969",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            borderRadius: "0.5vw",
          }}
        >
          <img
            style={{ width: "100%", height: "auto" }}
            src={librayImg}
            alt="edit nft"
          />
          <Button sx={{ color: "#fff", alignSelf: "flex-end" }}>Edit</Button>
        </Box>
      </Box>
    );
  };
  const myLibrary = () => {
    return (
      <Box
        sx={{
          width: "100%",
          padding: 3,
          minHeight: { xl: "79vh", lg: "76vh" },
        }}
      >
        <LibraryData title="animations" />
        <LibraryData title="Pose" />
      </Box>
    );
  };

  const UnityUI = ({ mode }) => {
    const [modelJson, setModelJson] = useState({});

    const unityBuildUrl =
      id == "boredapeyachtclub" || id === "mutant-ape-yacht-club"
        ? {
            loaderUrl:
              "https://fe4d5f6b-5d0e-47d6-814f-6c9b01688eb3.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/00d38a12-618d-4389-9595-278f9dd53087/release_by_badge/latest/entry_by_path/content/?path=Yuga_3D_Tools.loader.js",
            frameworkUrl:
              "https://fe4d5f6b-5d0e-47d6-814f-6c9b01688eb3.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/00d38a12-618d-4389-9595-278f9dd53087/release_by_badge/latest/entry_by_path/content/?path=Yuga_3D_Tools.framework.js.unityweb",
            dataUrl:
              "https://fe4d5f6b-5d0e-47d6-814f-6c9b01688eb3.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/00d38a12-618d-4389-9595-278f9dd53087/release_by_badge/latest/entry_by_path/content/?path=Yuga_3D_Tools.data.unityweb",
            codeUrl:
              "https://fe4d5f6b-5d0e-47d6-814f-6c9b01688eb3.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/00d38a12-618d-4389-9595-278f9dd53087/release_by_badge/latest/entry_by_path/content/?path=Yuga_3D_Tools.wasm.unityweb",
            streamingAssetsUrl:
              "https://fe4d5f6b-5d0e-47d6-814f-6c9b01688eb3.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/00d38a12-618d-4389-9595-278f9dd53087/release_by_badge/latest/entry_by_path/content/?path=StreamingAssets",
          }
        : {
            loaderUrl:
              "https://7ef40650-26ac-4365-9a53-56ef81e33968.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/60bd74e1-c7d5-450d-a501-21d4b15a3408/release_by_badge/latest/entry_by_path/content/?path=AnimationsAndPoses.loader.js",
            frameworkUrl:
              "https://7ef40650-26ac-4365-9a53-56ef81e33968.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/60bd74e1-c7d5-450d-a501-21d4b15a3408/release_by_badge/latest/entry_by_path/content/?path=build.framework.js",
            dataUrl:
              "https://7ef40650-26ac-4365-9a53-56ef81e33968.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/60bd74e1-c7d5-450d-a501-21d4b15a3408/release_by_badge/latest/entry_by_path/content/?path=webgl.data",
            codeUrl:
              "https://7ef40650-26ac-4365-9a53-56ef81e33968.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/60bd74e1-c7d5-450d-a501-21d4b15a3408/release_by_badge/latest/entry_by_path/content/?path=build.wasm",
            streamingAssetsUrl:
              "https://7ef40650-26ac-4365-9a53-56ef81e33968.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/60bd74e1-c7d5-450d-a501-21d4b15a3408/release_by_badge/latest/entry_by_path/content/?path=StreamingAssets",
          };
    const unity = useUnityContext({
      ...unityBuildUrl,
      // loaderUrl: "/webgl/RecordTest.loader.js",
      // frameworkUrl: "/webgl/RecordTest.framework.js",
      // dataUrl: "/webgl/RecordTest.data",
      // codeUrl: "/webgl/RecordTest.wasm",
      // streamingAssetsUrl: "/webgl/StreamingAssets",
      // cacheControl: handleCacheControl,

      webglContextAttributes: {
        preserveDrawingBuffer: true,
      },
    });

    const [modeType, setModeType] = useState(
      mode === "animate"
        ? "Animations"
        : mode === "MBA"
        ? "MBA"
        : mode === "pose"
        ? "Pose"
        : "Animations"
    );

    const RenderItems = () => {
      const animati = useSelector((e) => e?.model?.animations);
      const poses = useSelector((e) => e?.model?.pose);
      const posesObj =
        useSelector((e) => e?.model?.pose)?.find((e) => e.selected) || [];
      const pose = posesObj[Object.keys(posesObj)[0]]?.find(
        (e) => e.selected
      )?.name;
      const env = useSelector((e) => e?.model?.env);
      const envObj =
        useSelector((e) => e?.model?.env)?.find((e) => e.selected) || [];
      const environment = envObj[Object.keys(envObj)[0]]?.find(
        (e) => e.selected
      )?.name;
      const { EAELoaded, productLoaded } = useSelector((e) => e?.model);
      const exp = useSelector((e) => e?.model?.expressions);
      const mba = useSelector((e) => e?.model?.mba);
      const mbaObj =
        useSelector((e) => e?.model?.mba)?.find((e) => e.selected) || [];

      const mbaData = mbaObj[Object.keys(mbaObj)[0]]?.find(
        (e) => e.selected
      )?.name;
      const [open, setOpen] = React.useState(false);
      const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpen(false);
      };

      const [expanded, setExpanded] = React.useState("");

      const handleChange = (panel) => (event, newExpanded) => {
        setRenderInsideIcon({ enable: false, i: null });
        setExpanded(newExpanded ? panel : false);
      };

      const action = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <IoMdCloseCircleOutline fontSize="medium" />
          </IconButton>
        </React.Fragment>
      );
      const [renderItems, setRenderItems] = useState(
        modeType === "Expressions"
          ? exp
          : modeType === "Environments"
          ? env
          : modeType === "MBA"
          ? mba
          : modeType === "Animations" && mode === "animate"
          ? animati
          : poses
      );
      useEffect(() => {
        setRenderItems(
          modeType === "Expressions"
            ? exp
            : modeType === "Environments"
            ? env
            : modeType === "MBA"
            ? mba
            : modeType === "Animations" && mode === "animate"
            ? animati
            : poses
        );
      }, [modeType, mode]);
      useEffect(() => {
        if (modeType !== "Environments" && modeType !== "Expressions") {
          setModeType(
            mode === "animate"
              ? "Animations"
              : mode === "MBA"
              ? "MBA"
              : mode === "pose"
              ? "Pose"
              : "Environments"
          );
        }
      }, [mode]);
      const decreaseAmount = () => {
        dispatch(decreaseBalance());
      };
      const [renderInsideIcon, setRenderInsideIcon] = useState({
        enable: false,
        i: null,
      });
      return (
        <>
          <Snackbar
            open={open}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            autoHideDuration={3000}
            onClose={handleClose}
            message={
              <Box display="flex" alignItems="center">
                <img
                  width="25px"
                  src="https://storage.googleapis.com/3d-container/coins.png"
                  alt="coin"
                />
                <Typography
                  sx={{
                    marginLeft: "5px",
                    fontSize: "0.8vw",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  <strong>Success :</strong>`You have $CR8 left`
                </Typography>
              </Box>
            }
            action={action}
            sx={{
              "& .MuiSnackbarContent-root": {
                border: "1px solid #FFB800",
                borderRadius: "6px",
              },
            }}
          />
          {loader ? (
            <LoadingOverlay />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                overflowY: "scroll",
                height: "100%",
                width: "100%",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: "100%" }}>
                {renderItems.map((acor, index) => {
                  const renderToMap = renderInsideIcon.enable
                    ? acor[Object.keys(acor)[0]][renderInsideIcon.i]?.items ||
                      []
                    : acor[Object.keys(acor)[0]];
                  return (
                    <Accordion
                      key={index}
                      expanded={expanded === Object.keys(acor)[0]}
                      onChange={handleChange(Object.keys(acor)[0])}
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "var(--font-g)",
                              fontWeight: "700",
                              textTransform: "capitalize",
                              fontSize: { xl: "1.5em", lg: "1.2em", md: "1em" },
                            }}
                          >
                            {Object.keys(acor)[0]}
                          </Typography>
                          {expanded === Object.keys(acor)[0] && (
                            <SlReload
                              onClick={(e) => {
                                e.stopPropagation();
                                if (Object.keys(acor)[0] === "eyes") {
                                  for (let key in allEyes) {
                                    allEyes[key].find((e, i) => {
                                      if (e === modelJson?.Eyes) {
                                        unity?.sendMessage(
                                          "Manager",
                                          "EnableEyesTraits",
                                          "true"
                                        );
                                        return unity?.sendMessage(
                                          "Manager",
                                          "ChangeEyes",
                                          key
                                        );
                                      }
                                    });
                                  }
                                  return;
                                }
                                for (let key in allFaces) {
                                  allFaces[key].find((e, i) => {
                                    if (Object.keys(acor)[0] === "mouth") {
                                      unity?.sendMessage(
                                        "Manager",
                                        "EnableMouthTraits",
                                        "true"
                                      );
                                      return unity?.sendMessage(
                                        "Manager",
                                        "ChangeMouth",
                                        key
                                      );
                                    }
                                  });
                                  return;
                                }
                                if (modeType === "Animations") {
                                  return unity?.sendMessage(
                                    "Manager",
                                    "ChangeAnimations",
                                    "Emote#DwarfIdle"
                                  );
                                }
                                if (modeType === "Environments") {
                                  return unity?.sendMessage(
                                    "Manager",
                                    "EnableEnvironment",
                                    "Blank"
                                  );
                                }
                                if (modeType === "Pose" || modeType === "MBA") {
                                  return unity?.sendMessage(
                                    "Manager",
                                    "ChangeAnimations",
                                    "Pose#T_Pose"
                                  );
                                }
                              }}
                              sx={{ fontSize: "0.9rem", color: "#fff" }}
                            />
                          )}
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        {renderInsideIcon.enable && (
                          <Box
                            sx={{ cursor: "pointer", alignItems: "center" }}
                            display="flex"
                            onClick={() =>
                              setRenderInsideIcon({ enable: false, i: null })
                            }
                          >
                            <FaChevronLeft color="#fff" />
                            <Typography>Back</Typography>
                          </Box>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {renderToMap.map((item, i) => {
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <TooltipCustom>
                                  <Box
                                    key={i}
                                    onClick={() => {
                                      if (!EAELoaded || !productLoaded) return;
                                      decreaseAmount();
                                      const updatedAnimationPose =
                                        renderItems.map((pose, i) => {
                                          const key = Object.keys(pose)[0];
                                          if (i === index) {
                                            return {
                                              [key]: acor[
                                                Object.keys(acor)[0]
                                              ].map((it) => {
                                                if (it.name === item.name) {
                                                  return {
                                                    ...it,
                                                    selected: true,
                                                  };
                                                }
                                                return {
                                                  ...it,
                                                  selected: false,
                                                };
                                              }),
                                              selected: true,
                                            };
                                          }
                                          return {
                                            [key]: pose[
                                              Object.keys(pose)[0]
                                            ].map((it) => {
                                              if (it.name === item.name) {
                                                return {
                                                  ...it,
                                                  selected: false,
                                                };
                                              }
                                              return {
                                                ...it,
                                                selected: false,
                                              };
                                            }),
                                            selected: false,
                                          };
                                        });
                                      if (
                                        modeType === "Animations" &&
                                        mode === "animate"
                                      ) {
                                        dispatch(
                                          setModelAnimations(
                                            updatedAnimationPose
                                          )
                                        );
                                      }
                                      if (
                                        modeType === "Pose" &&
                                        mode === "pose"
                                      ) {
                                        dispatch(
                                          setModelPoses(updatedAnimationPose)
                                        );
                                      }
                                      if (modeType === "Environments") {
                                        dispatch(
                                          setModelEnv(updatedAnimationPose)
                                        );
                                      }
                                      if (modeType === "Expressions") {
                                        dispatch(
                                          setModelExpressions(
                                            updatedAnimationPose
                                          )
                                        );
                                      }
                                      if (modeType === "MBA") {
                                        dispatch(
                                          setModelMBA(updatedAnimationPose)
                                        );
                                      }
                                    }}
                                    sx={{
                                      cursor: "pointer",
                                      border: "1px solid #474747",
                                      padding: "15px",
                                      margin: "10px",
                                      borderRadius: "0.8vw",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                      "&:hover": {
                                        border: "1px solid #FFB800",
                                      },
                                    }}
                                  >
                                    <Avatar
                                      sx={{
                                        width: {
                                          xs: "16vw",
                                          lg: "5vw",
                                          xl: "5vw",
                                          sm: "25vw",
                                        },
                                        height: {
                                          xs: "16vw",
                                          lg: "5vw",
                                          xl: "5vw",
                                          sm: "25vw",
                                        },
                                        borderRadius: "0.8vw",
                                      }}
                                      variant="square"
                                      src={item.image_url}
                                      alt="model animation and poses"
                                    />
                                    {(!EAELoaded || !productLoaded) &&
                                      (item?.name === environment ||
                                        item?.name === pose ||
                                        item?.name === mbaData) && (
                                        <Box
                                          sx={{
                                            position: "absolute",
                                          }}
                                        >
                                          <div className="loader-cloth"></div>
                                        </Box>
                                      )}
                                  </Box>
                                </TooltipCustom>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {item.displayName && (
                                    <Typography
                                      sx={{
                                        fontFamily: "var(--font-i_r)",
                                        textAlign: "center",
                                        color: "#E3DFD6",
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        fontSize: {
                                          xs: "1.5vw",
                                          sm: "1.25vw",
                                          md: "0.8vw",
                                          lg: "0.6vw",
                                          xl: "0.5vw",
                                        },
                                        maxWidth: "10vw", // Adjust this value to ensure the text wraps as needed
                                        wordBreak: "break-word",
                                        whiteSpace: "normal",
                                      }}
                                    >
                                      {item.displayName}
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Box>
            </Box>
          )}
        </>
      );
    };
    return (
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid
          item
          xs={12}
          md={mode == "inspect" ? 12 : 8}
          lg={mode == "inspect" ? 12 : 8}
          alignItems="center"
          justifyContent="center"
        >
          <Box sx={{ minHeight: "75vh" }}>
            {dataLoaded && (
              <ApeUnity
                setModelJson={setModelJson}
                modelJson={modelJson}
                unity={unity}
                mode={modeType === "Expressions" ? "Expressions" : mode}
                tabMode={mode}
              />
            )}
          </Box>
        </Grid>
        {mode !== "inspect" && (
          <Grid item xs={12} md={4} lg={4} paddingRight={{ xl: 2, lg: 1 }}>
            <Box
              sx={{
                width: "100%",
                height: "75vh",
                borderRadius: "0.5vw",
                display: "flex",
                flexDirection: "column",
                border: "1px solid #FFB800",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  paddingBlock: "18px",
                  justifyContent: "center",
                }}
              >
                {(id === "boredapeyachtclub" ||
                  id === "mutant-ape-yacht-club") && (
                  <ProjectButton
                    width={{ xl: "7vw", lg: "6.9vw", md: "6.5vw", sm: "auto" }}
                    project={"Expressions"}
                    setProject={setModeType}
                    modeType={modeType}
                  />
                )}
                <ProjectButton
                  project={
                    mode === "animate"
                      ? "Animations"
                      : mode === "pose"
                      ? "Pose"
                      : "MBA"
                  }
                  width={
                    id === "boredapeyachtclub" || id === "mutant-ape-yacht-club"
                      ? { xl: "7vw", lg: "6.9vw", md: "6.5vw", sm: "auto" }
                      : false
                  }
                  setProject={setModeType}
                  modeType={modeType}
                />
                <ProjectButton
                  project={"Environments"}
                  setProject={setModeType}
                  modeType={modeType}
                  width={
                    id === "boredapeyachtclub" || id === "mutant-ape-yacht-club"
                      ? { xl: "7vw", lg: "6.9vw", md: "6.5vw", sm: "auto" }
                      : false
                  }
                />
              </Box>
              <RenderItems />
            </Box>
          </Grid>
        )}
      </Grid>
    );
  };

  const Animation = () => {
    return (
      <Box sx={{ width: "100vw" }}>
        <UnityUI mode="animate" />
      </Box>
    );
  };
  const RealSpaceAr = () => {
    return (
      <Box
        sx={{
          width: "100vw",
          minHeight: { xl: "79vh", lg: "76vh" },
          padding: { xl: 10, lg: 4, md: 4, xs: 2, sm: 2 },
        }}
      >
        <UnityComponent />
      </Box>
    );
  };
  const Pose = () => {
    return (
      <Box sx={{ width: "100vw" }}>
        <UnityUI mode="pose" />
      </Box>
    );
  };
  const Inspect = () => {
    return (
      <Box sx={{ width: "100vw" }}>
        <UnityUI mode="inspect" />
      </Box>
    );
  };
  const MBA = () => {
    return (
      <Box sx={{ width: "100vw" }}>
        <UnityUI mode="MBA" />
      </Box>
    );
  };
  const Scenes = () => {
    return (
      <Box
        sx={{
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Typography
          sx={{
            fontWeight: "800",
            fontFamily: "var(--font-f_r)",
            fontSize: "1.5vw",
            textAlign: "left",
            cursor: "pointer",
            textTransform: "capitalize",
            color: "#FFB800",
          }}
        >
          Under Construction
        </Typography> */}
        <UnityUIScenes mode="scenes" />
      </Box>
    );
  };
  const layoutOptions = [
    {
      name: "Inspect",
      children: Inspect(),
    },
    {
      name: "Animation",
      children: Animation(),
    },
    {
      name: "Pose",
      children: Pose(),
    },
    {
      name: "MBA Experience",
      children: MBA(),
    },
    {
      name: "Scene",
      children: Scenes(),
    },
    {
      name: "Merch Tryout",
      navigate: "merch",
    },
    {
      name: "MBA Closet",
      navigate: "MBACloset",
    },
    {
      name: "RealSpace AR",
      children: RealSpaceAr(),
    },
    {
      name: "My Library",
      children: myLibrary(),
    },
  ];
  const isClothUI =
    id === "boredapeyachtclub" || id === "mutant-ape-yacht-club";
  console.log(isClothUI, "isClothUI");
  return (
    <DashboardLayout
      layoutOptions={layoutOptions?.filter((e) =>
        !isClothUI &&
        (e.name === "Merch Tryout" ||
          e.name === "MBA Closet" ||
          e.name === "Scene" ||
          e.name === "MBA Experience")
          ? false
          : true
      )}
      title="3D TOOLS"
    />
  );
}
export const IconContainer = ({ onClick, icon: Icon }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        height: "2vw",
        width: "2vw",
        marginLeft: "1vw",
        border: "1px solid rgba(255, 184, 0,0.6)",
        p: 0.5,
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "center",
      }}
    >
      <Icon size={18} color="#FFB800" />
    </Box>
  );
};
export default Dashboard3d;

export const infoItems = [
  {
    topContent: null,
    img: "https://storage.googleapis.com/3d-container/svgs/3d/Rotate.svg",
    bottomContent: "Rotate",
  },
  {
    topContent: null,
    img: "https://storage.googleapis.com/3d-container/svgs/3d/Zoom.svg",
    bottomContent: "Zoom",
  },
  {
    topContent: null,
    img: "https://storage.googleapis.com/3d-container/svgs/3d/Move.svg",
    bottomContent: "Move",
  },
];
