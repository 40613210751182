import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";

export const ProjectButton = ({
  project,
  setProject,
  modeType,
  width,
  height,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        cursor: "pointer",
      }}
      onClick={() => {
        setProject(project);
      }}
    >
      <Box
        sx={{
          width: width ? width : { xl: "11.2vw", lg: "11vw", md: "10.5vw" },
          minHeight: height ? height : "5vh",
          border: "1px solid #FFB800",
          borderRadius: "0.5vw",
          alignItems: "center",
          marginInline: "0.5vw",
          display: "flex",
          justifyContent: "center",
          background: modeType === project ? "#FFB800" : "@000",
          [theme.breakpoints.down("sm")]: {
            width: "11vh",
            marginBottom: "25px",
            height: "45px",
            borderRadius: "0.4vw",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontFamily: "var(--font-f_r)",
            userSelect: "none",
            fontSize: {
              xl: "1.2em",
              lg: "1em",
              md: "0.7em",
              xs: "0.8em",
              sm: "0.8em",
            },
            textTransform: "capitalize",
            textAlign: "center",
            color: modeType === project ? "#000" : "#FFB800",
          }}
        >
          {project === "MBA" ? "Brands" : project}
        </Typography>
      </Box>
    </Box>
  );
};
