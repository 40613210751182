import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import * as React from "react";
import { useTheme } from "@emotion/react";
import Header from "../Header";
import { useNavigate } from "react-router-dom";

import BackButton from "../BackButton";
import FooterHome from "../footer/FooterHome";
import MobileHeader from "../MobileHeader";
import MobileFooter from "../MobileFooter";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useState } from "react";

function DashboardLayout({ layoutOptions, title }) {
  const loading = useSelector((e) => e?.model?.recording) || false;

  const { palette, ...theme } = useTheme();
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        backgroundPosition: "bottom",
        backgroundSize: "cover",
        backgroundImage:
          "url('https://storage.googleapis.com/3d-container/bg.png')",
      }}
    >
      {isMobile ? <MobileHeader></MobileHeader> : <Header hamburger={true} />}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100vw",
        }}
      >
        <Box
          sx={{
            width: "96%",
            marginTop: "5vw",
            marginBottom: "2vh",
            border: "1px solid #FFB800",
            borderRadius: "0.8vw",
            backdropFilter: "blur(10px)",
            padding: 2,
          }}
        >
          <Grid
            container
            spacing={0}
            alignItems="flex-start"
            justifyContent="flex-start"
            sx={{
              minHeight: {
                xl: "79vh",
                lg: "77vh",
                md: "75vh",
                xs: "auto",
                sm: "auto",
              },
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              alignItems="flex-start"
              justifyContent="center"
            >
              <Box>
                <BackButton />
                <Typography
                  onClick={() => navigate(-1)}
                  sx={{
                    fontWeight: "800",
                    fontFamily: "var(--font-f_r)",
                    fontSize: "1.5vw",
                    textAlign: "left",
                    cursor: "pointer",
                    textTransform: "capitalize",

                    color: "#FFB800",
                    marginRight: "5px",
                  }}
                >
                  {title}
                </Typography>
                <Box
                  width="100%"
                  sx={{
                    [theme.breakpoints.down("sm")]: {
                      display: "flex",
                      flexWrap: "wrap",
                    },
                  }}
                >
                  {layoutOptions?.map((option, i) => {
                    return (
                      <Box
                        key={i}
                        onClick={() => {
                          if (loading)
                            return enqueueSnackbar(
                              "Can't change the page. The video is under recording",
                              {
                                variant: "warning",
                                anchorOrigin: {
                                  vertical: "top",
                                  horizontal: "right",
                                },
                              }
                            );
                          option?.navigate
                            ? navigate(option?.navigate)
                            : setStep(i);
                        }}
                        sx={{
                          cursor: "pointer",
                          fontWeight: "600",
                          padding: 2,
                          margin: 2,
                          [theme.breakpoints.down("sm")]: {
                            width: "40%",
                          },
                          [theme.breakpoints.down("lg")]: {
                            padding: "5px",
                          },
                          height: "45px",
                          borderRadius: "0.5vw",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "90%",
                          border:
                            step === i
                              ? `1px solid ${palette.button.default}`
                              : "none",
                          background:
                            step === i
                              ? `${palette.button.default}`
                              : "transparent",
                          color: step === i ? `black` : "transparent",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "500",
                            fontFamily: "var(--font-i_r)",
                            fontSize: {
                              xl: "1.2em",
                              lg: "1em",
                              md: "0.8em",
                              xs: "1em",
                              sm: "1em",
                            },
                            textTransform: "capitalize",
                            textAlign: "start",
                            color: step === i ? `black` : "#fff",
                            [theme.breakpoints.down("sm")]: {
                              fontSize: "14px",
                            },
                            [theme.breakpoints.down("lg")]: {
                              fontSize: "10px",
                            },
                          }}
                        >
                          {option.name}
                        </Typography>
                        {option.name === "Scene" && (
                          <Typography
                            sx={{
                              fontWeight: "500",
                              backgroundColor: "#FFB800",
                              px: "20px",
                              py: "6px",
                              mr: 1.5,
                              borderRadius: "20px",
                              fontFamily: "var(--font-i_r)",
                              fontSize: {
                                xl: "1em",
                                lg: "0.8em",
                                md: "0.6em",
                                xs: "1em",
                                sm: "1em",
                              },
                              textTransform: "capitalize",
                              textAlign: "start",
                              color: `black`,
                              [theme.breakpoints.down("sm")]: {
                                fontSize: "14px",
                              },
                              [theme.breakpoints.down("lg")]: {
                                fontSize: "10px",
                              },
                            }}
                          >
                            Testing
                          </Typography>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={10}
              lg={10}
              xl={10}
              sx={{
                backgroundColor: "#000",
                borderRadius: "15px",
                minHeight: "78vh",
              }}
            >
              {layoutOptions[step] && layoutOptions[step]?.children}
            </Grid>
          </Grid>
        </Box>
      </Box>
      {isMobile ? <MobileFooter></MobileFooter> : <FooterHome />}
    </Box>
  );
}

export default DashboardLayout;
