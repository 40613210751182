import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  styled,
} from "@mui/material";

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))((props) => {
  console.log(props);

  return {
    border: props?.expanded ? `1px solid #FFB800` : "1px solid #000",
    background: props?.expanded ? "#000" : "#242424",
    marginBlock: "10px",
    // marginInline: "1vw",
    borderRadius: "10px",
    padding: "0px",
    color: "#fff",
    fontSize: "1rem",
    fontFamily: "var(--font-f_r)",
    // "&:not(:last-child)": {
    //   borderBottom: 0,
    // },
    "&::before": {
      display: "none",
    },
  };
});

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary {...props} expandIcon={null} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
