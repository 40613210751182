import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Typography, useMediaQuery } from "@mui/material";

const ModelTool = ({
  icon: Icon,
  shaders,
  onSelectShader,
  onClickAction,
  startIcon: StartIcon,
  name,
  startIconClick = null,
  endIconClick = null,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (!shaders) {
      onClickAction();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (shader) => {
    onSelectShader(shader);
    handleClose();
  };
  const isMobile = useMediaQuery("(max-width:599px)");

  return (
    <Box>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup={shaders ? "true" : "false"}
        aria-expanded={open ? "true" : "false"}
        onClick={handleClick}
        startIcon={
          StartIcon && (
            <StartIcon onClick={(e) => startIconClick && startIconClick(e)} />
          )
        }
        endIcon={<Icon onClick={(e) => endIconClick && endIconClick(e)} />}
        variant="outlined"
        sx={{ textTransform: "capitalize" }}
      >
        <span style={{ display: isMobile ? "none" : "" }}>{name}</span>
      </Button>
      {shaders && (
        <Menu
          sx={{
            "& .MuiPaper-root": {
              mt: "4px",
              // Target the paper element inside the Menu
              border: "1px solid #ffb800",
              backgroundColor: "#000", // Apply the background color here as well
            },
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          dense
        >
          {shaders.map((shader, index) => (
            <MenuItem
              key={index}
              onClick={() => handleOptionClick(shader.value)}
              sx={{
                fontFamily: "var(--font-i_r)",
                textTransform: "capitalize",
                color: "#fff !important",
                "&:hover": {
                  backgroundColor: "#FFB800 !important", // Change background color on hover
                  color: "#000 !important",
                },
                "&.Mui-selected": {
                  backgroundColor: "rgba(0,0,0,0.8)", // Change background color when selected
                },
              }}
            >
              {shader.key}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  );
};

export default ModelTool;
