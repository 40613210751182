import { useTheme } from "@emotion/react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  checkboxClasses,
  FormControlLabel,
  LinearProgress,
  linearProgressClasses,
  Menu,
  MenuItem,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme as useThemeMaterial } from "@mui/material/styles";

import * as React from "react";
import { Helmet } from "react-helmet-async";
import { BiExport } from "react-icons/bi";
import { FaBold, FaCaretDown, FaItalic, FaRedo, FaUndo } from "react-icons/fa";
import { GiShadowFollower } from "react-icons/gi";
import { Unity, useUnityContext } from "react-unity-webgl";
import {
  exitFullscreen,
  GPUChecker,
  handleFullscreen,
  rgba2hex,
  STORAGE_URL,
} from "../../../utils";
import FooterHome from "../../footer/FooterHome";
import "../../Loader.css";
import MobileFooter from "../../MobileFooter";
import { UploadStickerDialog } from "../../ShareDialog/ShareDialog";
import ModelTool from "../dashboard3d/ModelTool";
import { HoveredIconContainer } from "../dashboard3d/UnityModel";
import ColorPicker from "react-best-gradient-color-picker";
import ConnectWallet from "../../buttons/ConnectWallet";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "10px",

  width: "60%",
  borderRadius: 3,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 3,
    backgroundColor: "#FFB800",
  },
}));

function Model3d() {
  const { palette, ...theme } = useTheme();
  const [shaders, setShaders] = React.useState(null);
  const [isCharacter, setIsCharacter] = React.useState(false);

  const [fullScreen, setFullScreen] = React.useState(false);
  const elementRef = React.useRef(null);

  const [loadingName, setLoadingName] = React.useState(
    "Gathering intergalactic building blocks..."
  );
  const [pixelRatio, setPixelRatio] = React.useState(null);
  const isMobile = useMediaQuery("(max-width:599px)");
  React.useEffect(() => {
    GPUChecker(setPixelRatio);
  }, []);

  const unity = useUnityContext({
    loaderUrl:
      "https://7ef40650-26ac-4365-9a53-56ef81e33968.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/9cb1e65b-e3d7-42c9-8b7c-cb368eb4b2bd/release_by_badge/latest/entry_by_path/content/?path=FarAway.loader.js",
    frameworkUrl:
      "https://7ef40650-26ac-4365-9a53-56ef81e33968.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/9cb1e65b-e3d7-42c9-8b7c-cb368eb4b2bd/release_by_badge/latest/entry_by_path/content/?path=FarAway.framework.js.unityweb",
    dataUrl:
      "https://7ef40650-26ac-4365-9a53-56ef81e33968.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/9cb1e65b-e3d7-42c9-8b7c-cb368eb4b2bd/release_by_badge/latest/entry_by_path/content/?path=FarAway.data.unityweb",
    codeUrl:
      "https://7ef40650-26ac-4365-9a53-56ef81e33968.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/9cb1e65b-e3d7-42c9-8b7c-cb368eb4b2bd/release_by_badge/latest/entry_by_path/content/?path=FarAway.wasm.unityweb",
    streamingAssetsUrl:
      "https://7ef40650-26ac-4365-9a53-56ef81e33968.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/9cb1e65b-e3d7-42c9-8b7c-cb368eb4b2bd/release_by_badge/latest/entry_by_path/content/?path=StreamingAssets",

    // loaderUrl: "/webgl/FarAway.loader.js",
    // frameworkUrl: "/webgl/FarAway.framework.js.unityweb",
    // dataUrl: "/webgl/FarAway.data.unityweb",
    // codeUrl: "/webgl/FarAway.wasm.unityweb",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  React.useEffect(() => {
    let percentProgress = unity?.loadingProgression * 100;
    if (percentProgress > 0 && percentProgress < 20) {
      setLoadingName(
        "Counting polygons, one by one (seriously, there are a lot)..."
      );
    }
    if (percentProgress > 20 && percentProgress < 40) {
      setLoadingName("Warming up our virtual 3D printing press...");
    }
    if (percentProgress > 40 && percentProgress < 60) {
      setLoadingName("Summoning the 3D gnomes to do their thing...");
    }
    if (percentProgress > 60 && percentProgress < 80) {
      setLoadingName(
        "Rounding up all the lost 3D sheep... just kidding, it's code!"
      );
    }
    if (percentProgress > 80 && percentProgress < 100) {
      setLoadingName("Holding back impatient 3D squirrels - they're nuts!");
    }
  }, [unity?.loadingProgression]);
  const bikeColors = [
    {
      name: "1",
      type: "A",
      img: "D6D7D6",
      selected: false,
    },
    {
      name: "2",
      type: "A",
      img: "6D6D39",
      selected: false,
    },
    {
      name: "3",
      type: "A",
      img: "B2DAE7",
      selected: false,
    },
    {
      name: "4",
      type: "A",
      img: "39AA10",
      selected: false,
    },
    {
      name: "5",
      type: "A",
      img: "198CA1",
      selected: false,
    },
    {
      name: "6",
      type: "A",
      img: "66596B",
      selected: false,
    },
    {
      name: "7",
      type: "A",
      img: "81D9AC",
      selected: false,
    },
    {
      name: "8",
      type: "A",
      img: "E761AD",
      selected: false,
    },
    {
      name: "9",
      type: "A",
      img: "860F2F",
      selected: false,
    },
    {
      name: "10",
      type: "A",
      img: "4A6079",
      selected: false,
    },
    {
      name: "11",
      type: "A",
      img: "C8913C",
      selected: false,
    },
    {
      name: "12",
      type: "A",
      img: "930D9B",
      selected: false,
    },
    {
      name: "13",
      type: "A",
      img: "D6DFA5",
      selected: false,
    },
    {
      name: "1",
      type: "B",
      img: "D6D7D6",
      selected: false,
    },
    {
      name: "2",
      type: "B",
      img: "6D6D39",
      selected: false,
    },
    {
      name: "3",
      type: "B",
      img: "B2DAE7",
      selected: false,
    },
    {
      name: "4",
      type: "B",
      img: "39AA10",
      selected: false,
    },
    {
      name: "5",
      type: "B",
      img: "198CA1",
      selected: false,
    },
    {
      name: "6",
      type: "B",
      img: "81D9AC",
      selected: false,
    },
    {
      name: "7",
      type: "B",
      img: "E761AD",
      selected: false,
    },
    {
      name: "8",
      type: "B",
      img: "C8913C",
      selected: false,
    },
    {
      name: "9",
      type: "B",
      img: "4A6079",
      selected: false,
    },
    {
      name: "10",
      type: "B",
      img: "68596B",
      selected: false,
    },
    {
      name: "11",
      type: "B",
      img: "BB4E58",
      selected: false,
    },
    {
      name: "12",
      type: "B",
      img: "D6DFA5",
      selected: false,
    },
    {
      name: "1",
      type: "C",
      img: "D6D7D6",
      selected: false,
    },
    {
      name: "2",
      type: "C",
      img: "6D6D39",
      selected: false,
    },
    {
      name: "3",
      type: "C",
      img: "B2DAE7",
      selected: false,
    },
    {
      name: "4",
      type: "C",
      img: "39AA10",
      selected: false,
    },
    {
      name: "5",
      type: "C",
      img: "A569DE",
      selected: false,
    },
    {
      name: "6",
      type: "C",
      img: "81D9AC",
      selected: false,
    },
    {
      name: "7",
      type: "C",
      img: "E761AD",
      selected: false,
    },
    {
      name: "8",
      type: "C",
      img: "4A6079",
      selected: false,
    },
    {
      name: "9",
      type: "C",
      img: "C8913C",
      selected: false,
    },
    {
      name: "10",
      type: "C",
      img: "68596B",
      selected: false,
    },
    {
      name: "11",
      type: "C",
      img: "BB4E58",
      selected: false,
    },
    {
      name: "12",
      type: "C",
      img: "D6DFA5",
      selected: false,
    },
  ];
  const [panels, setPanels] = React.useState([
    {
      name: "Vehicles",
      selected: true,
      type: "ChangeBike",
      icon: STORAGE_URL + "bikeModel/bike.png",
      varaints: [
        {
          name: "A",
          img: STORAGE_URL + "bikeModel/A.png",
          selected: false,
        },
        {
          name: "B",
          img: STORAGE_URL + "bikeModel/B.png",
          selected: false,
        },
        {
          name: "C",
          img: STORAGE_URL + "bikeModel/C.png",
          selected: false,
        },
      ],
    },
    {
      name: "Colors",
      selected: false,
      type: "ChangeBike",
      icon: STORAGE_URL + "bikeModel/color.png",
      varaints: [],
    },
    {
      name: "Text and Stickers",
      selected: false,
      type: "ChangeStickers",
      icon: STORAGE_URL + "bikeModel/sticker.png",
      varaints: [
        {
          name: "Null",
          img: STORAGE_URL + "ddu/NoSticker.png",
          selected: false,
        },
        {
          name: "Splash",
          img: STORAGE_URL + "ddu/stickers/Stickers-01.png",
          selected: false,
        },
        {
          name: "Biohazard",
          img: STORAGE_URL + "ddu/stickers/Stickers-02.png",
          selected: false,
        },
        {
          name: "Banana",
          img: STORAGE_URL + "ddu/stickers/Stickers-03.png",
          selected: false,
        },
        {
          name: "Cows",
          img: STORAGE_URL + "ddu/stickers/Stickers-04.png",
          selected: false,
        },

        {
          name: "caution",
          img: STORAGE_URL + "ddu/stickers/caution.png",
          selected: false,
        },
        {
          name: "danger",
          img: STORAGE_URL + "ddu/stickers/danger.png",
          selected: false,
        },
        {
          name: "noTea",
          img: STORAGE_URL + "ddu/stickers/noTea.png",
          selected: false,
        },
        {
          name: "shit",
          img: STORAGE_URL + "ddu/stickers/shit.png",
          selected: false,
        },
        {
          name: "stop",
          img: STORAGE_URL + "ddu/stickers/stop.png",
          selected: false,
        },
        {
          name: "warn",
          img: STORAGE_URL + "ddu/stickers/warn.png",
          selected: false,
        },
        {
          name: "why",
          img: STORAGE_URL + "ddu/stickers/why.png",
          selected: false,
        },
        {
          name: "wtf",
          img: STORAGE_URL + "ddu/stickers/wtf.png",
          selected: false,
        },
      ],
    },
  ]);
  const handleSelectShader = (shader) => {
    setShaders(shader);
  };
  React.useEffect(() => {
    if (unity?.isLoaded) {
      const selected = panels?.find((e) => e.selected);
      unity?.sendMessage("Manager", "ChangeStickers", "Null");
      unity?.sendMessage(
        "Manager",
        "Preview",
        selected?.name === "Text and Stickers" ? 0 : 1
      );
      if (selected?.varaints?.find((e) => e.selected)?.name) {
        if (selected?.name !== "Vehicles") {
          unity?.sendMessage(
            "Manager",
            selected.type,
            selected?.name === "Text and Stickers"
              ? selected?.varaints?.find((e) => e.selected)?.name
              : selected?.varaints?.find((e) => e.selected)?.name + " " + 1
          );
        }
      }
    }
  }, [unity?.isLoaded, panels]);

  React.useEffect(() => {
    if (unity?.isLoaded && shaders) {
      unity?.sendMessage("Manager", "ChangeShaders", shaders);
    }
  }, [unity?.isLoaded, shaders]);

  React.useEffect(() => {
    if (unity?.isLoaded) {
      unity?.sendMessage(
        "Manager",
        "ChangeCharacters",
        isCharacter ? "A" : "Null"
      );
    }
  }, [unity?.isLoaded, isCharacter]);

  return (
    <Box
      sx={{
        backgroundPosition: "bottom",
        backgroundSize: "cover",
      }}
    >
      <Helmet>
        <title>DashBO : Unclogged</title>
        <meta name="description" content={"DashBO : Unclogged"} />
        <meta property="og:title" content="DashBO : Unclogged" />
        <meta property="og:description" content="DashBO : Unclogged." />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/3d-container/ddu/NewDDu.png"
        />
        <meta property="og:url" content="https://dashbo.xyz/ddu" />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          // alignItems: "",
          height: "100vh",
          width: "100vw",

          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${STORAGE_URL}ddu/DDU_BG.webp)`,
          backgroundSize: "cover",
          [theme.breakpoints.down("md")]: {
            height: "100vh",
          },
          [theme.breakpoints.down("sm")]: {
            height: "100%",
          },
        }}
      >
        <img
          style={{
            width: "160px",
            position: "absolute",
            top: "1.5vw",
            left: "2vw",
            height: "auto",
          }}
          src={STORAGE_URL + "ddu/DDULogo.png"}
        />
        <Typography
          sx={{
            color: "#fff",
            textTransform: "capitalize",
            fontFamily: "var(--font-f_r)",
            color: "#ffb800",
            position: "absolute",
            top: "1.5vw",
            right: "2vw",
            height: "auto",
            fontSize: "1.3vw",
            [theme.breakpoints.down("md")]: {
              fontSize: "12px",
            },
          }}
        >
          Beta V-1.0
        </Typography>
        <Box
          sx={{
            width: "96%",
            marginTop: "5vw",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            height: "82%",
            borderRadius: "0.8vw",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "70vh",
              width: "15%",
              [theme.breakpoints.down("md")]: {
                width: "100%",
                height: "10vh",
                mt: 8,
                flexDirection: "row",
                justifyContent: "space-between",
              },
            }}
          >
            {panels.map((panel) => {
              return (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    background: "#121212",
                    cursor: "pointer",
                    border: panel?.selected ? "1px solid #ffb800" : "",
                    borderRadius: "0.5vw",
                    my: 2,
                    px: 1,
                    py: 2,
                    [theme.breakpoints.down("md")]: {
                      width: "20%",
                      borderRadius: "0.5vw",
                      my: 0,
                      px: 0,
                      py: 0,
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    },
                  }}
                  onClick={() => {
                    const updated = panels.map((pa) => {
                      if (panel.name === pa.name)
                        return {
                          ...pa,
                          selected: true,
                        };
                      return {
                        ...pa,
                        varaints: pa.name === "Colors" ? [] : pa?.varaints,
                        selected: false,
                      };
                    });
                    setPanels(updated);
                  }}
                >
                  <img
                    style={{
                      marginInline: "0.8vw",
                      width: isMobile ? "20%" : "10%",
                    }}
                    src={panel.icon}
                  />
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "1vw",
                      textAlign: "center",
                      [theme.breakpoints.down("md")]: {
                        fontSize: "12px",
                      },
                    }}
                  >
                    {panel.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>

          <Box
            sx={{
              marginInline: "0.8vw",
              [theme.breakpoints.down("md")]: {
                marginInline: "0",
                width: "100%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBlock: "1.5vh",
              }}
            >
              <FormControlLabel
                value={isCharacter}
                sx={{
                  color: "#ffb800",
                  fontSize: "1vw",
                }}
                control={
                  <Checkbox
                    size="large"
                    onChange={() =>
                      panels[0]?.varaints?.find((e) => e.selected) &&
                      setIsCharacter(!isCharacter)
                    }
                    checked={isCharacter}
                    sx={{
                      [`&, &.${checkboxClasses.checked}`]: {
                        color: "#ffb800",
                      },
                    }}
                  />
                }
                label="Enable Character"
                labelPlacement="end"
              />
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <ModelTool
                  icon={GiShadowFollower}
                  shaders={[
                    { key: "Default Shader", value: "DefaultShader" },
                    { key: "Toon Shader", value: "ToonShader" },
                  ]}
                  onSelectShader={handleSelectShader}
                  name="choose shader"
                />
                <Box sx={{ marginLeft: "1vw" }}>
                  <ModelTool
                    icon={BiExport}
                    onClickAction={() => {
                      unity?.sendMessage("Manager", "ExportGLB");
                    }}
                    name="Export"
                  />
                </Box>
              </Box>
            </Box>

            <Box
              ref={elementRef}
              onDoubleClick={() =>
                fullScreen
                  ? exitFullscreen(elementRef, setFullScreen)
                  : handleFullscreen(elementRef, setFullScreen)
              }
              sx={{
                width: fullScreen
                  ? "100vw"
                  : { xl: "60vw", lg: "60vw", md: "96vw" },
                // height: "100%",
                height: fullScreen
                  ? "100vh"
                  : {
                      xl: "67vh",
                      lg: "66vh",
                      md: "44vh",
                      sm: "50vh",
                    },
              }}
              id="videoAnimation"
            >
              {!unity?.isLoaded && (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "0.8vw",
                    background: "#000",
                    display: unity?.isLoaded ? "none" : "flex",
                    alignItems: "center",
                    // margin: "auto",
                    flexDirection: "column",
                    justifyContent: "center",
                    [theme.breakpoints.down("sm")]: {
                      height: "30vh",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "800",
                      fontFamily: "var(--font-f_r)",
                      fontSize: isMobile ? "12px" : "1vw",
                      mb: 2,
                      width: "100%",
                      textAlign: "center",
                      textTransform: "capitalize",
                      color: "#C4C4C4",
                    }}
                  >
                    {loadingName}
                  </Typography>
                  <BorderLinearProgress
                    variant="determinate"
                    value={unity?.loadingProgression * 100}
                  />
                </Box>
              )}
              {pixelRatio && (
                <Unity
                  style={{
                    width: unity?.isLoaded ? "100%" : "0%",
                    height: unity?.isLoaded ? "100%" : "0%",
                    borderRadius: "0.8vw",
                  }}
                  devicePixelRatio={pixelRatio}
                  unityProvider={unity?.unityProvider}
                />
              )}
            </Box>
          </Box>

          <Box
            className="no-scroll"
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "70vh",
              width: "22%",
              p: 1,
              borderRadius: "0.8vw",
              background: "#121212",
              overflowY: "scroll",
              [theme.breakpoints.down("md")]: {
                width: "100%",
                height:
                  panels?.find((e) => e.selected)?.name === "Text"
                    ? "40vh"
                    : "20vh",
                flexDirection: "row",
                justifyContent: "space-between",
              },
              [theme.breakpoints.down("sm")]: {
                my: 1,
                height:
                  panels?.find((e) => e.selected)?.name === "Text"
                    ? "35vh"
                    : "14vh",
              },
            }}
          >
            <RenderItems
              items={panels.find((e) => e.selected)}
              panels={panels}
              setPanels={setPanels}
              unity={unity}
              bikeColors={bikeColors}
            />
          </Box>
        </Box>
      </Box>
      {isMobile ? <MobileFooter ddu /> : <FooterHome ddu />}
    </Box>
  );
}

export default Model3d;

const RenderItems = ({ items, panels, setPanels, unity, bikeColors }) => {
  const [open, setOpen] = React.useState(false);
  const [showColorSelector, setShowColorSelector] = React.useState(false);
  const [assetLoaded, setAssetLoaded] = React.useState(true);
  const [selectedAsset, setSelectedAsset] = React.useState(null);

  const [fonts, setFonts] = React.useState({
    text: "",
    color: "#ffb800",
    size: 10,
    family: "Choose Font",
    type: "normal",
  });
  const { palette, ...theme } = useTheme();
  const themeMaterial = useThemeMaterial();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const fontsFamily = [
    {
      key: "Bebas Neue",
      value: "0",
    },
    {
      key: "Grey Qo",
      value: "1",
    },
    {
      key: "Matemasie",
      value: "2",
    },
    {
      key: "Serrem",
      value: "3",
    },
    {
      key: "Serrem Outline",
      value: "4",
    },
    {
      key: "Shlop",
      value: "5",
    },
  ];
  const openAnchor = Boolean(anchorEl);
  const handleFontType = (type) => {
    setFonts({
      ...fonts,
      type,
    });
  };
  const isMobile = useMediaQuery("(max-width:599px)");
  const selectedVehicle = panels[0]?.varaints?.find((e) => e.selected);
  const colorRef = React.useRef(null);

  const handleClickOutsideColor = (event) => {
    if (colorRef.current && !colorRef.current.contains(event.target)) {
      setShowColorSelector(false);
    }
  };

  const handleColorChange = (newColor) => {
    if (newColor.includes("linear")) {
      setFonts({
        ...fonts,
        color: newColor,
      });
    } else {
      const hex = rgba2hex(newColor);
      setFonts({
        ...fonts,
        color: hex,
      });
    }
  };
  React.useEffect(() => {
    if (unity?.addEventListener) {
      unity?.addEventListener("AssetLoaded", (e) => {
        setAssetLoaded(e ? true : false);
      });
      return () => {
        unity?.removeEventListener("AssetLoaded", () => {});
      };
    }
  }, [unity?.addEventListener, unity?.removeEventListener, window]);
  React.useEffect(() => {
    if (showColorSelector) {
      document.addEventListener("mousedown", handleClickOutsideColor);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideColor);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideColor);
    };
  }, [showColorSelector]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        className="no-scroll"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "center",
          [theme.breakpoints.down("md")]: {
            display: items?.name === "Text and Stickers" ? "block" : "flex",
            justifyContent: "flex-start",
            flexWrap: "nowrap",
            "::-webkit-scrollbar-track": {
              backgroundColor: "transparant !important",
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: "transparant !important",
            },
            "::-webkit-scrollbar": {
              width: "0px !important",
            },
          },
        }}
      >
        {items?.name === "Colors" &&
          bikeColors
            ?.filter((e) => e.type === selectedVehicle?.name)
            ?.map((item) => {
              return (
                <Box
                  onClick={() => {
                    if (!assetLoaded) return;
                    setSelectedAsset(item?.name);
                    unity?.sendMessage(
                      "Manager",
                      "ChangeBike",
                      item?.type + " " + item.name
                    );
                  }}
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "0.8vw",
                    m: 1,
                  }}
                >
                  {!assetLoaded && selectedAsset === item?.name && (
                    <Box
                      sx={{
                        position: "absolute",
                      }}
                    >
                      <div className="loader-cloth"></div>
                    </Box>
                  )}
                  <Box
                    sx={{
                      width: {
                        xs: "16vw",
                        lg: "5vw",
                        xl: "5vw",
                        md: "150px",
                        sm: "50px",
                      },
                      height: {
                        xs: "16vw",
                        lg: "5vw",
                        xl: "5vw",
                        md: "150px",
                        sm: "50px",
                      },
                      borderRadius: "0.8vw",
                      bgcolor: "#" + item.img,
                    }}
                  />
                </Box>
              );
            })}

        {items?.name === "Text and Stickers" && (
          <Box sx={{ width: "100%" }}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "800",
                    fontFamily: "var(--font-f_r)",
                    fontSize: "1vw",
                    mb: 1,
                    width: "100%",
                    textAlign: "left",
                    textTransform: "capitalize",
                    color: "#C4C4C4",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "12px",
                      mb: 0,
                    },
                  }}
                >
                  Undo/Redo
                </Typography>
                <HoveredIconContainer
                  selected={fonts.type === "bold"}
                  Icon={FaUndo}
                  onClick={() => unity?.sendMessage("Manager", "Undo")}
                />
                <HoveredIconContainer
                  selected={fonts.type === "bolditalic"}
                  Icon={FaRedo}
                  onClick={() => unity?.sendMessage("Manager", "Redo")}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontWeight: "800",
                    fontFamily: "var(--font-f_r)",
                    fontSize: "1vw",
                    mb: 1,
                    width: "100%",
                    textAlign: "left",
                    textTransform: "capitalize",
                    color: "#C4C4C4",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "12px",
                      mb: 0,
                    },
                  }}
                >
                  Add Text (Multiple Text Supported)
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <input
                    className="textarea"
                    style={{
                      border: "none",
                      width: "70%",
                      borderRadius: isMobile ? "5px" : "10px",
                      padding: isMobile ? "5px" : "10px",
                      height: isMobile ? "30px" : "40px",
                      background: "#222423",
                      color: "#fff",
                    }}
                    value={fonts.text}
                    onChange={(e) =>
                      setFonts({ ...fonts, text: e.target.value })
                    }
                    placeholder="Text"
                  />
                  <ConnectWallet
                    smallButton
                    sx={{
                      color: "#000 !important",
                      backgroundColor: themeMaterial.palette.button.default,
                      borderColor: themeMaterial.palette.button.default,
                      fontWeight: "800",
                      "&:hover": {
                        color: "white !important",
                      },
                    }}
                    onClick={() => {
                      const font = {
                        ...fonts,
                        family:
                          fontsFamily?.find((e) => e.key === fonts.family)
                            ?.value || "Bebas Neue",
                      };
                      unity?.sendMessage(
                        "Manager",
                        "ChangeText",
                        JSON.stringify(font)
                      );
                    }}
                    label="Apply"
                  />
                </Box>
              </Box>

              <Box sx={{ width: "100%", mt: 1.5 }}>
                <Box
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "40px",
                      display: "flex",
                      // paddingBlock: "5px",
                      paddingInline: "10px",
                      fontSize: "0.8vw",
                      color: "#000",
                      width: "24%",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      onClick={() => {
                        if (fonts.size > 2)
                          return setFonts({
                            ...fonts,
                            size: fonts.size - 1,
                          });
                      }}
                      sx={{
                        userSelect: "none",
                        fontSize: "1.2vw",
                        cursor: "pointer",
                        [theme.breakpoints.down("md")]: {
                          fontSize: "12px",
                        },
                      }}
                    >
                      -
                    </Typography>
                    <Typography>{fonts.size}</Typography>
                    <Typography
                      onClick={() => {
                        setFonts({
                          ...fonts,
                          size: fonts.size + 1,
                        });
                      }}
                      sx={{
                        userSelect: "none",
                        fontSize: "1.2vw",
                        cursor: "pointer",
                      }}
                    >
                      +
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      width: "20%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setShowColorSelector(!showColorSelector);
                    }}
                  >
                    <Box
                      sx={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "8px",
                        marginLeft: "4px",
                        backgroundColor: fonts.color,
                      }}
                    />
                    <FaCaretDown color="#fff" size={30} />
                  </Box>
                  <HoveredIconContainer
                    selected={fonts.type === "bold"}
                    Icon={FaBold}
                    onClick={() => handleFontType("bold")}
                  />
                  <HoveredIconContainer
                    selected={fonts.type === "bolditalic"}
                    Icon={FaItalic}
                    onClick={() => handleFontType("bolditalic")}
                  />
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup={"true"}
                    aria-expanded={open ? "true" : "false"}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    variant="outlined"
                    sx={{ textTransform: "capitalize", width: "40%" }}
                  >
                    {fonts.family}
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={openAnchor}
                    onClose={() => setAnchorEl(null)}
                    dense
                  >
                    {fontsFamily.map((shader, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          setFonts({
                            ...fonts,
                            family: shader.key,
                          });

                          setAnchorEl(null);
                        }}
                        sx={{
                          fontFamily: "var(--font-i_r)",
                          textTransform: "capitalize",

                          "&:hover": {
                            backgroundColor: "#FFB800 !important", // Change background color on hover
                            color: "#000 !important",
                          },
                          "&.Mui-selected": {
                            backgroundColor: "rgba(0,0,0,0.8)", // Change background color when selected
                          },
                        }}
                      >
                        {shader.key}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>

                {showColorSelector && (
                  <div
                    ref={colorRef}
                    style={{
                      position: "absolute",
                      zIndex: 1,
                      backgroundColor: "white",

                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ColorPicker
                      value={fonts.color}
                      hidePresets
                      hideInputs
                      hideControls
                      hideOpacity
                      onChange={(e) => handleColorChange(e)}
                    />
                  </div>
                )}
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontWeight: "800",
                    fontFamily: "var(--font-f_r)",
                    fontSize: "1vw",
                    my: 2,
                    width: "100%",
                    textAlign: "left",
                    textTransform: "capitalize",
                    color: "#C4C4C4",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "12px",
                      mb: 0,
                    },
                  }}
                >
                  Stickers
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {items?.name === "Text and Stickers" && (
          <Box
            onClick={() => {
              setOpen(true);
            }}
            sx={{
              cursor: "pointer",
              margin: "10px",
            }}
          >
            {" "}
            <Avatar
              sx={{
                width: {
                  xs: "16vw",
                  lg: "5vw",
                  xl: "5vw",
                  md: "150px",
                  sm: "50px",
                },
                height: {
                  xs: "16vw",
                  lg: "5vw",
                  xl: "5vw",
                  md: "150px",
                  sm: "50px",
                },
              }}
              variant="square"
              src={STORAGE_URL + "ddu/UploadSticker.png"}
              alt={STORAGE_URL + "ddu/UploadSticker.png"}
            />
          </Box>
        )}
        {(items?.name === "Vehicles" || items?.name === "Text and Stickers") &&
          items?.varaints?.map((item) => {
            return (
              <Box
                onClick={() => {
                  if (!assetLoaded) return;
                  setSelectedAsset(item?.name);
                  const updated = items?.varaints?.map((it) => {
                    if (it?.name === item?.name)
                      return {
                        ...it,
                        selected: true,
                      };
                    return {
                      ...it,
                      selected: false,
                    };
                  });
                  setPanels(() =>
                    panels?.map((panel) => {
                      if (panel?.name === items.name)
                        return {
                          ...panel,
                          varaints: updated,
                        };
                      return {
                        ...panel,
                      };
                    })
                  );
                  if (items?.name === "Vehicles") {
                    unity?.sendMessage(
                      "Manager",
                      "ChangeBike",
                      item?.name + " " + 1
                    );
                  }
                }}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "10px",
                  borderRadius: "0.8vw",
                }}
              >
                {!assetLoaded && selectedAsset === item?.name && (
                  <Box
                    sx={{
                      position: "absolute",
                      zIndex: 10000,
                    }}
                  >
                    <div className="loader-cloth"></div>
                  </Box>
                )}
                <Avatar
                  sx={{
                    width: {
                      xs: "16vw",
                      lg: "5vw",
                      xl: "5vw",
                      md: "150px",
                      sm: "50px",
                    },
                    height: {
                      xs: "16vw",
                      lg: "5vw",
                      xl: "5vw",
                      md: "150px",
                      sm: "50px",
                    },
                    borderRadius: "0.8vw",
                  }}
                  variant="square"
                  src={item.img}
                  alt={item.id}
                />
              </Box>
            );
          })}

        <UploadStickerDialog
          open={open}
          setOpen={setOpen}
          onUpload={() => {
            unity?.sendMessage("Manager", "UploadTexture");
          }}
        />
      </Box>
    </Box>
  );
};
