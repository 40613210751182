import { BASEURL } from "../utils";

export const getModelData = async (id, signal, collection) => {
  try {
    const response = await fetch(`${BASEURL}nfts/${id}/${collection}`, {
      signal,
    });
    return await response.json();

    // Your data handling logic here
  } catch (error) {
    if (error.name === "AbortError") {
      console.log("Request was aborted");
    } else {
      console.error("API request error:", error);
    }
  }
};
export const getSidebarMenuBySlugan = async (id) => {
  try {
    const response = await fetch(`${BASEURL}modelcustomizationsByType/${id}`);
    const data = await response.json();
    return data;
  } catch (error) {
    if (error.name === "AbortError") {
      console.log("some thing went wrong with get side bar menu");
    } else {
      console.error("API request error:", error);
    }
  }
};

export const getARModelData = async (id, signal) => {
  try {
    const response = await fetch(`${BASEURL}nfts/armodel/${id}`, { signal });
    return await response.json();

    // Your data handling logic here
  } catch (error) {
    if (error.name === "AbortError") {
      console.log("Request was aborted");
    } else {
      console.error("API request error:", error);
    }
  }
};

export const getModelDataPrint3d = async (id, signal) => {
  try {
    const response = await fetch(`${BASEURL}/nfts/3dprint/${id}`, { signal });
    return await response.json();

    // Your data handling logic here
  } catch (error) {
    if (error.name === "AbortError") {
      console.log("Request was aborted");
    } else {
      console.error("API request error:", error);
    }
  }
};

export const getNftMerchandise = async (id, showLoader, hideLoader) => {
  showLoader();
  try {
    const response = await fetch(`${BASEURL}merchandise/${id}`);
    hideLoader();
    return await response.json();

    // Your data handling logic here
  } catch (error) {
    hideLoader();
    if (error.name === "AbortError") {
      console.log("Request was aborted");
    } else {
      console.error("API request error:", error);
    }
  }
};
