import React, { useEffect, useRef, useState } from "react";

import { useTheme } from "@emotion/react";
import {
  Box,
  CircularProgress,
  Dialog,
  Divider,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { newLibrary3d } from "../../slice/library.slice";
import ConnectWallet from "../buttons/ConnectWallet";
import { MdSkipNext, MdSkipPrevious } from "react-icons/md";
import {
  FaChevronDown,
  FaPauseCircle,
  FaPlay,
  FaPlayCircle,
  FaSpotify,
} from "react-icons/fa";
import { STORAGE_URL } from "../../utils";
import { IoCloudDownloadSharp } from "react-icons/io5";
import { Accordion, AccordionDetails, AccordionSummary } from "./Accordion";

export const ShareDialog = ({
  mode,
  openShare,
  setOpenShare,
  onFBShare,
  onDownlaod,
  onTwitterShare,
  downloadType,
  setDownloadType,
  duration,
  setDuration,
}) => {
  const [fileName, setFileName] = useState(null);
  const [error, setError] = useState("");

  const type = mode === "animate" || mode === "scenes" ? "HighRes" : "PNG";
  useEffect(() => {
    setDownloadType(type);
  }, [type]);
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const [buttons, setButtons] = useState([
    // {
    //   name: "Save",
    //   selected: true,
    // },
    {
      name: "Download",
      selected: true,
    },
    // {
    //   name: "Share",
    //   selected: false,
    // },
  ]);
  const socialButtons = [
    {
      name: "Twitter",
      icon: "https://storage.googleapis.com/3d-container/social-icons/twitter.png",
      onClick: () => onTwitterShare(),
    },
    {
      name: "Instagram",
      icon: "https://storage.googleapis.com/3d-container/social-icons/instagram.png",
      onClick: () => {},
    },
    {
      name: "Facebook",
      icon: "https://storage.googleapis.com/3d-container/social-icons/facebook.png",
      onClick: () => onFBShare(),
    },
    {
      name: "WhatsApp",
      icon: "https://storage.googleapis.com/3d-container/social-icons/whatsapp.png",
      onClick: () => {},
    },
    {
      name: "Email",
      icon: "https://storage.googleapis.com/3d-container/social-icons/mail.png",
      onClick: () => {},
    },
    {
      name: "Youtube",
      icon: "https://storage.googleapis.com/3d-container/social-icons/youtube.png",
      onClick: () => {},
    },
    {
      name: "Discord",
      icon: "https://storage.googleapis.com/3d-container/social-icons/discord.png",
      onClick: () => {},
    },
    {
      name: "Telegram",
      icon: "https://storage.googleapis.com/3d-container/social-icons/telegram.png",
      onClick: () => {},
    },
  ];

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const numberValue = Number(value);
      if (numberValue > 30) {
        setError("Duration cannot exceed 30 seconds");
      } else if (numberValue < 1 && value !== "") {
        setError("Duration cannot be less than 1 second");
      } else {
        setError("");
        setDuration(value);
      }
    } else {
      setError("Please enter a valid number");
    }
  };
  const getTime = () => {
    switch (downloadType) {
      case "LowRes":
        if (duration <= 10) {
          return 15;
        }
        if (duration > 10 && duration <= 20) {
          return 30;
        }
        if (duration > 20) {
          return 1;
        }
      case "HighRes":
        if (duration <= 10) {
          return 30;
        }
        if (duration > 10 && duration <= 20) {
          return 50;
        }
        if (duration > 20) {
          return 1.3;
        }
      case "UltraHD":
        if (duration <= 10) {
          return 2;
        }
        if (duration > 10 && duration <= 20) {
          return 4;
        }
        if (duration > 20) {
          return 6;
        }
      default:
        return "black";
    }
  };
  const getTimeUnit = () => {
    switch (downloadType) {
      case "LowRes":
        if (duration <= 10) {
          return "seconds";
        }
        if (duration > 10 && duration <= 20) {
          return "seconds";
        }
        if (duration > 20) {
          return "minutes";
        }
      case "HighRes":
        if (duration <= 10) {
          return "seconds";
        }
        if (duration > 10 && duration <= 20) {
          return "seconds";
        }
        if (duration > 20) {
          return "minutes";
        }
      case "UltraHD":
        if (duration <= 10) {
          return "minutes";
        }
        if (duration > 10 && duration <= 20) {
          return "minutes";
        }
        if (duration > 20) {
          return "minutes";
        }
    }
  };
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        disableEnforceFocus
        slotProps={{
          backdrop: {
            style: {
              zIndex: 2,
              cursor: "pointer",
              backgroundColor: "#000",
              opacity: "0.7",
            },
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: "#000",
            zIndex: 10,
            height: "40%",
            border: `1px solid ${palette.button.default}`,
            borderRadius: "20px",
          },
        }}
        onClose={() => setOpenShare(false)}
        open={openShare}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {buttons.map((button, i) => {
              return (
                <Box
                  key={i + "dialogButtons"}
                  sx={{
                    cursor: "pointer",
                    px: 4,
                    py: 1,
                    background: button.selected ? palette.button.default : "",
                  }}
                  onClick={() => {
                    const updated = buttons.map((btn) => {
                      if (btn.name === button.name) {
                        return {
                          ...btn,
                          selected: true,
                        };
                      }
                      return {
                        ...btn,
                        selected: false,
                      };
                    });
                    setButtons(updated);
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontFamily: "var(--font-f_r)",
                      fontSize: {
                        xl: "1vw",
                        lg: "1vw",
                        md: "1vw",
                        xs: "4vw",
                        sm: "4vw",
                      },
                      textTransform: "capitalize",
                      textAlign: "center",
                      color: button.selected ? "black" : "#fff",
                    }}
                  >
                    {button.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <RxCross2
            style={{ marginRight: "20px", cursor: "pointer" }}
            onClick={() => setOpenShare(false)}
            color={"#F85B03"}
            size={25}
          />
        </Box>
        <Divider
          style={{
            width: "100%",
            height: "1px",
            border: "1px solid #0F0F0F",
          }}
        />
        {buttons.find((e) => e.selected)?.name === "Save" && (
          <Box p={3} display="flex" alignItems="end" width="100%">
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid
                item
                justifyContent="center"
                alignItems="center"
                lg={8}
                xl={8}
                xs={12}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontFamily: "var(--font-i_r)",
                    fontSize: "14px",
                    color: "#ffff",
                    alignSelf: "flex-start",
                    marginBottom: "5px",
                  }}
                >
                  File Name
                </Typography>
                <TextField
                  id="name"
                  variant="outlined"
                  placeholder="Enter file name ..."
                  fullWidth
                  sx={{
                    color: "#fff",
                    fontFamily: "var(--font-i_r)",
                    "& .MuiInputBase-input": { color: "#fff" },
                    "& .MuiInputLabel-root": { color: "#fff" },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: palette.button.default,
                      },
                  }}
                  value={fileName}
                  onChange={(e) => {
                    setFileName(e.target.value);
                  }}
                />
              </Grid>
              <Grid
                item
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                xs={12}
                xl={4}
                lg={4}
              >
                <ConnectWallet
                  onClick={() => {
                    dispatch(
                      newLibrary3d({
                        name: "newfile here",
                        data: "https://storage.googleapis.com/3d-container/bg.png",
                      })
                    );
                  }}
                  isHeader
                  label="  save to library"
                  fullWidth
                  sx={{
                    margin: {
                      xl: "2.5vh 0px 0px 0px ",
                      lg: "2.5vh 0px 0px 0px ",
                    },
                  }}
                ></ConnectWallet>
              </Grid>
            </Grid>
          </Box>
        )}
        {buttons.find((e) => e.selected)?.name === "Download" && (
          <>
            <Box p={3} display="flex" alignItems="end" width="100%">
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid
                  item
                  justifyContent="flex-start"
                  alignItems="center"
                  lg={8}
                  xl={8}
                  xs={12}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontFamily: "var(--font-i_r)",
                      fontSize: "14px",
                      color: "#ffff",
                      alignSelf: "flex-start",
                      marginBottom: "5px",
                    }}
                  >
                    File Name
                  </Typography>
                  <TextField
                    id="name"
                    variant="outlined"
                    placeholder="Enter file name ..."
                    fullWidth
                    sx={{
                      color: "#fff",
                      fontFamily: "var(--font-i_r)",
                      "& .MuiInputBase-input": { color: "#fff" },
                      "& .MuiInputLabel-root": { color: "#fff" },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: palette.button.default,
                        },
                    }}
                    value={fileName}
                    onChange={(e) => {
                      setFileName(e.target.value);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  justifyContent="flex-start"
                  alignItems="center"
                  lg={4}
                  xl={4}
                  xs={12}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontFamily: "var(--font-i_r)",
                      fontSize: "14px",
                      color: "#ffff",
                      alignSelf: "flex-start",
                      marginBottom: "5px",
                    }}
                  >
                    File Format
                  </Typography>
                  {mode === "animate" || mode === "scenes" ? (
                    <FormControl fullWidth>
                      <Select
                        value={downloadType}
                        onChange={(e) => {
                          setDownloadType(e.target.value);
                        }}
                        sx={{
                          width: "100%",
                          color: "#fff",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: palette.button.default,
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: palette.button.default,
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#fff",
                          },
                        }}
                        defaultValue={downloadType}
                      >
                        <MenuItem value="LowRes">Low Res</MenuItem>
                        <MenuItem value="HighRes">High Res</MenuItem>
                        <MenuItem value="UltraHD">Ultra HD</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl fullWidth>
                      <Select
                        value={downloadType}
                        onChange={(e) => {
                          setDownloadType(e.target.value);
                        }}
                        sx={{
                          width: "100%",
                          color: "#fff",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: palette.button.default,
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: palette.button.default,
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#fff",
                          },
                        }}
                        defaultValue={downloadType}
                      >
                        <MenuItem value="PNG">PNG</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box p={3} display="flex" alignItems="end" width="100%">
              <Grid
                container
                spacing={2}
                justifyContent="right"
                alignItems="center"
              >
                {(mode === "animate" || mode === "scenes") && (
                  <Grid
                    item
                    justifyContent="center"
                    alignItems="center"
                    lg={8}
                    xl={8}
                    xs={12}
                  >
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontFamily: "var(--font-i_r)",
                        fontSize: "14px",
                        color: "#ffff",
                        alignSelf: "flex-start",
                        marginBottom: "5px",
                      }}
                    >
                      Duration (max 30 seconds)
                    </Typography>
                    <TextField
                      variant="outlined"
                      placeholder="Enter file name ..."
                      fullWidth
                      sx={{
                        color: "#fff",
                        fontFamily: "var(--font-i_r)",
                        "& .MuiInputBase-input": { color: "#fff" },
                        "& .MuiInputLabel-root": { color: "#fff" },
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#fff",
                          },
                      }}
                      value={duration}
                      onChange={handleChange}
                      error={Boolean(error)}
                      helperText={error}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  justifyContent="right"
                  alignItems="center"
                  lg={4}
                  xl={4}
                  xs={12}
                >
                  <ConnectWallet
                    label="Download"
                    onClick={() => {
                      if (!duration) return setError("Duration is required");
                      onDownlaod(fileName, duration);
                    }}
                    isHeader
                    fullWidth
                    sx={{ margin: "2.5vh 0px 0px 0px " }}
                  />
                </Grid>
                {(mode === "animate" || mode === "scenes") && (
                  <Grid
                    item
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    lg={12}
                    xl={12}
                    xs={12}
                  >
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontFamily: "var(--font-i_r)",
                        fontSize: "22px",
                        color: "#ffff",
                        alignSelf: "flex-start",
                        marginBottom: "5px",
                      }}
                    >
                      Note:
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontFamily: "var(--font-i_r)",
                        fontSize: "18px",
                        color: "#ffff",
                        alignSelf: "flex-start",
                        marginBottom: "5px",
                      }}
                    >
                      It will take aproximately{" "}
                      <span style={{ color: "#ffb800" }}>
                        {getTime()} {getTimeUnit()}{" "}
                      </span>{" "}
                      to download recorded video.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </>
        )}
        {buttons.find((e) => e.selected)?.name === "Share" && (
          <Box p={3} display="flex" alignItems="center" width="100%">
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              {socialButtons.map((btn) => (
                <Grid item key={btn.name} xs={12} sm={6} md={4}>
                  <Box
                    onClick={() => {
                      setOpenShare(false);
                      btn.onClick();
                    }}
                    sx={{
                      display: "flex",
                      cursor: "pointer",
                      borderRadius: "10px",

                      justifyContent: "center",
                      alignItems: "center",
                      p: 2,
                      m: 2,
                      background: "#181818",
                    }}
                  >
                    <img src={btn.icon} alt={btn.icon} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                        textTransform: "capitalize",
                        fontFamily: "var(--font-f_r)",
                        fontSize: { xl: "1.2em", lg: "1.2em", md: "1em" },
                        color: "#FFB800",
                        ml: 2,
                      }}
                    >
                      {btn.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Dialog>
    </div>
  );
};

export const UploadDialog = ({
  open,
  setOpen,
  onUpload,
  setAudioName,
  audioName,
  setAudioSrc,
}) => {
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(false);

  const [audios, setAudios] = useState([
    {
      selected: true,
      thumbnail: STORAGE_URL + "yuga/sampleAudios/ArtistRedHat.png",
      audioList: [
        {
          url: STORAGE_URL + "yuga/sampleAudios/Davey%20Bones'%20Locker.mp3",
          spotify:
            "https://open.spotify.com/track/5g2Dhv6dxGhmRYcBQEnSkG?si=a7e0bc7bb94c4ff3",
          title: "Davey Bones' Locker",
          artist: "Red Hat",
          duration: 300,
          thumbnail: STORAGE_URL + "yuga/sampleAudios/DaveyBonesLocker.jpeg",
        },
        {
          url: STORAGE_URL + "yuga/sampleAudios/Franken%20Bombay.mp3",
          title: "Franken Bombay",
          spotify:
            "https://open.spotify.com/track/1WxgxOZ6NbAR3cn6tXkQKD?si=5df4292e42754526",
          artist: "Red Hat",
          duration: 300,
          thumbnail: STORAGE_URL + "yuga/sampleAudios/Franken%20Bombay.jpeg",
        },
        {
          url: STORAGE_URL + "yuga/sampleAudios/Fuck%20It%20Friday.mp3",
          title: "Fuck It Friday",
          artist: "Red Hat",
          spotify:
            "https://open.spotify.com/track/1XTiKbiMswVdZkQVhnqbYR?si=74ab735822164c36",
          duration: 300,
          thumbnail: STORAGE_URL + "yuga/sampleAudios/Fuck%20It%20Friday.jpeg",
        },
        {
          url: STORAGE_URL + "yuga/sampleAudios/Goodfellas.mp3",
          title: "Goodfellas",
          artist: "Red Hat",
          spotify:
            "https://open.spotify.com/track/1R4iPEmbDlMgF5URG9xrFX?si=6856ffe6e2bc4cfb",
          duration: 300,
          thumbnail: STORAGE_URL + "yuga/sampleAudios/Goodfellas.jpeg",
        },
        {
          url: STORAGE_URL + "yuga/sampleAudios/Sippin%20Serum.mp3",
          title: "Sippin Serum",
          artist: "Red Hat",
          spotify:
            "https://open.spotify.com/track/7tYtfsRWxuZ4tmv8Ak0H4J?si=97b58c8ff017445c",
          duration: 300,
          thumbnail: STORAGE_URL + "yuga/sampleAudios/Sippin%20Serum.jpeg",
        },
        {
          url:
            STORAGE_URL + "yuga/sampleAudios/Welcome%20to%20the%20Cartel.mp3",
          title: "Welcome to the Cartel",
          artist: "Red Hat",
          spotify:
            "https://open.spotify.com/track/5oJqyf1damIEnK1MZtX0r6?si=c065701b6c52462d",
          duration: 300,
          thumbnail:
            STORAGE_URL + "yuga/sampleAudios/Welcome%20to%20the%20Cartel.jpeg",
        },
      ],
      artist: "Red Hat",
    },
    {
      selected: false,
      thumbnail:
        STORAGE_URL + "yuga/sampleAudios/MyDeadFriendz/MyDeadFriendzArtist.png",
      audioList: [
        {
          url: STORAGE_URL + "yuga/sampleAudios/MyDeadFriendz/Embrace.mp3",
          spotify:
            "https://open.spotify.com/track/6sDrtahNRjk3Mbh4dwm80i?si=f7170865137944f3",
          title: "Embrace",
          artist: "MyDeadFriendz",
          duration: 300,
          thumbnail:
            STORAGE_URL + "yuga/sampleAudios/MyDeadFriendz/Embrace.png",
        },
        {
          url: STORAGE_URL + "yuga/sampleAudios/MyDeadFriendz/The%20March.mp3",
          title: "The March",
          spotify:
            "https://open.spotify.com/track/4NjBysdwELOAunqv85UDIf?si=db3b5d9a6d924ea4",
          artist: "MyDeadFriendz",
          duration: 300,
          thumbnail:
            STORAGE_URL + "yuga/sampleAudios/MyDeMyDeadFriendz/The%20March.PNG",
        },
        {
          url: STORAGE_URL + "yuga/sampleAudios/MyDeadFriendz/Royal.mp3",
          title: "Royal",
          artist: "MyDeadFriendz",
          spotify:
            "https://open.spotify.com/track/2E3WxNNTY3RWpn6FW2my1E?si=e7cb22d175294fbe",
          duration: 300,
          thumbnail: STORAGE_URL + "yuga/sampleAudios/MyDeadFriendz/Royal.png",
        },
        {
          url: STORAGE_URL + "yuga/sampleAudios/MyDeadFriendz/The%20Burial.mp3",
          title: "The Burial",
          artist: "MyDeadFriendz",
          spotify:
            "https://open.spotify.com/track/1efId4skUB9xgCNzMqj3KR?si=f058cbd7b3a647a0",
          duration: 300,
          thumbnail:
            STORAGE_URL + "yuga/sampleAudios/MyDeadFriendz/The%20Burial.png",
        },
      ],
      artist: "MyDeadFriendz",
    },
    {
      selected: false,
      thumbnail:
        STORAGE_URL +
        "yuga/sampleAudios/MutantsRecords/ArtistMutantsRecords.png",
      audioList: [
        {
          url:
            STORAGE_URL +
            "yuga/sampleAudios/MutantsRecords/Tommy%20Wheelz%20-%20Baddie%20Loop.mp3",
          spotify:
            "https://open.spotify.com/intl-de/track/3Q3nBIzBHkiGnA6Xa5m6RT?si=f838ef5defc94200",
          title: "Tommy Wheelz - Baddie",
          artist: "Mutant Records",
          duration: 300,
          thumbnail:
            STORAGE_URL +
            "yuga/sampleAudios/MutantsRecords/Tommy%20Wheelz%20-%20Baddie.jpg",
        },
        {
          url:
            STORAGE_URL +
            "yuga/sampleAudios/MutantsRecords/NaNa%20JJ%20-%20YaYa%20Tribe%20DBO.mp3",
          spotify:
            "https://open.spotify.com/intl-de/track/2de4CHASu5N35vPvMPedHc?si=c584a1876bd74c2e",
          title: "NaNa JJ - YaYa Tribe",
          artist: "Mutant Records",
          duration: 300,
          thumbnail:
            STORAGE_URL +
            "yuga/sampleAudios/MutantsRecords/YaYa%20Tribe%203000x3000.jpg",
        },
        {
          url:
            STORAGE_URL +
            "yuga/sampleAudios/MutantsRecords/NaNa%20JJ%20-%20YaYa%20Tribe%202%20DBO.mp3",
          spotify:
            "https://open.spotify.com/intl-de/track/2de4CHASu5N35vPvMPedHc?si=c584a1876bd74c2e",
          title: "NaNa JJ - YaYa Tribe 2",
          artist: "Mutant Records",
          duration: 300,
          thumbnail:
            STORAGE_URL +
            "yuga/sampleAudios/MutantsRecords/YaYa%20Tribe%203000x3000.jpg",
        },

        {
          url:
            STORAGE_URL +
            "yuga/sampleAudios/MutantsRecords/NaNa%20JJ%20-%20Misago%202%20DBO.mp3",
          spotify:
            "https://open.spotify.com/intl-de/track/0mVRPazYjMXo5sMJDqYK4z?si=78196542149c460a",
          title: "NaNa JJ. - Misago 2",
          artist: "Mutant Records",
          duration: 300,
          thumbnail:
            STORAGE_URL +
            "yuga/sampleAudios/MutantsRecords/Misago%20Cover%203000x3000%20(1).jpg",
        },
        {
          url:
            STORAGE_URL +
            "yuga/sampleAudios/MutantsRecords/NaNa%20JJ%20-%20Misago%20DBO.mp3",
          spotify:
            "https://open.spotify.com/intl-de/track/0mVRPazYjMXo5sMJDqYK4z?si=78196542149c460a",
          title: "NaNa JJ. - Misago",
          artist: "Mutant Records",
          duration: 300,
          thumbnail:
            STORAGE_URL +
            "yuga/sampleAudios/MutantsRecords/Misago%20Cover%203000x3000%20(1).jpg",
        },

        {
          url:
            STORAGE_URL +
            "yuga/sampleAudios/MutantsRecords/NaNa%20JJ%20-%20Ape%20Its%20a%20way%20DBO.mp3",
          spotify:
            "https://open.spotify.com/intl-de/track/2DuSkZVvMnpYET2Ql0vONu?si=eafa43dbb3954f31",
          title: "NaNa JJ. - Ape its a way",
          artist: "Mutant Records",
          duration: 300,
          thumbnail:
            STORAGE_URL +
            "yuga/sampleAudios/MutantsRecords/NaNa%20JJ%20-%20Ape%20Its%20a%20Way.png",
        },
        {
          url:
            STORAGE_URL +
            "yuga/sampleAudios/MutantsRecords/The8102%20feat.%20NaNa%20JJ%20-%20Hidden%20Island%20Theme.mp3",
          spotify:
            "https://open.spotify.com/intl-de/track/3UqTWjCZRr3UO9qcxxif76?si=db6881f994964dec",
          title: "The8102 feat. NaNa JJ - The Hidden island Theme",
          artist: "Mutant Records",
          duration: 300,
          thumbnail:
            STORAGE_URL +
            "yuga/sampleAudios/MutantsRecords/HIdden%20Island%20theme%201.png",
        },
      ],
      artist: "Mutant Records",
    },
  ]);
  const [progress, setProgress] = useState(0);
  const [selectedAudio, setAudio] = useState({
    url: "",
    title: "",
    artist: "",
    duration: "",
    thumbnail: "",
  });
  const audioRef = useRef(null);
  const togglePlayPause = () => {
    if (audioRef.current) {
      if (playing) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setPlaying(!playing);
    }
  };
  useEffect(() => {
    if (audioRef.current) {
      if (playing) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setPlaying(!playing);
    }
  }, [audioRef.current, selectedAudio]);
  useEffect(() => {
    if (audioRef.current) {
      const updateProgress = () => {
        if (audioRef.current) {
          const { currentTime, duration } = audioRef.current;
          setProgress((currentTime / duration) * 100);
        }
      };

      const audio = audioRef.current;
      audio.addEventListener("timeupdate", updateProgress);

      return () => {
        audio.removeEventListener("timeupdate", updateProgress);
      };
    }
  }, [audioRef.current]);
  const handlePredefinedAudio = async (url, title) => {
    try {
      setAudioName(title);
      setLoading(true);
      const response = await fetch(url, { mode: "cors" });
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      setLoading(false);
      setAudioSrc(blobUrl);
      setAudioName(title);
      setOpen(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching audio:", error);
    }
  };
  const [expanded, setExpanded] = React.useState("");

  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        disableEnforceFocus
        slotProps={{
          backdrop: {
            style: {
              zIndex: 2,
              cursor: "pointer",
              backgroundColor: "#000",
              opacity: "0.7",
            },
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: "#000",
            zIndex: 10,
            height: "60%",
            border: `1px solid #ffb800`,
            borderRadius: "20px",
          },
        }}
        onClose={() => setOpen(false)}
        open={open}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "var(--font-g)",
              fontSize: "0.8vw",
              fontWeight: 600,
              my: 1,
              color: "#C4C4C4",
            }}
          >
            MBA Music
          </Typography>
          {selectedAudio?.artist && (
            <Box
              sx={{
                display: "flex",
                width: "92%",
                marginInline: "auto",
                borderRadius: "0.5vw",
                backgroundColor: "#242424",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBlock: 1,
                px: 2,

                mb: 1,
              }}
            >
              <Box sx={{ display: "flex", width: "36%", alignItems: "center" }}>
                <Box
                  sx={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    alignItems: "center",
                    border: "2px solid #D8D5CD",
                    backgroundImage: `url('${selectedAudio.thumbnail}')`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                ></Box>
                <Box mx={1}>
                  <Typography
                    sx={{
                      fontFamily: "var(--font-g)",
                      fontSize: "0.6vw",
                      textWrap: "nowrap",
                      color: "#C4C4C4",
                    }}
                  >
                    {selectedAudio.title?.length > 20
                      ? selectedAudio.title?.substring(0, 20) + "..."
                      : selectedAudio.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "var(--font-g)",
                      fontSize: "14px",
                      color: "#C4C4C4",
                    }}
                  >
                    {selectedAudio.artist}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  width: "70%",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <MdSkipPrevious
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (selectedAudio.index > 0) {
                      setProgress(0);
                      setPlaying(false);
                      setAudio({
                        ...audios.find((e, i) => i === selectedAudio.index - 1),
                        index: selectedAudio.index - 1,
                      });
                    }
                  }}
                  color="#C4C4C4"
                  size={40}
                />
                <Box
                  onClick={togglePlayPause}
                  sx={{ cursor: "pointer" }}
                  mx={2}
                >
                  {playing ? (
                    <FaPauseCircle color="#C4C4C4" size={40} />
                  ) : (
                    <FaPlayCircle color="#C4C4C4" size={40} />
                  )}
                </Box>
                <MdSkipNext
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (selectedAudio.index < audios.length - 1) {
                      setProgress(0);
                      setPlaying(false);
                      setAudio({
                        ...audios.find((e, i) => i === selectedAudio.index + 1),
                        index: selectedAudio.index + 1,
                      });
                    }
                  }}
                  color="#C4C4C4"
                  size={40}
                />
                <LinearProgress
                  value={progress}
                  variant="determinate"
                  sx={{ width: "50%", ml: 2 }}
                />
                <audio ref={audioRef} src={selectedAudio.url} />
              </Box>
            </Box>
          )}

          <Box
            sx={{
              width: "92%",
              marginInline: "auto",
              height: "40%",
              overflowY: "scroll",
            }}
            className="no-scroll"
          >
            {audios.map((audio, index) => {
              return (
                <Accordion
                  key={index}
                  expanded={expanded === index}
                  onChange={handleChangeAccordian(index)}
                >
                  <AccordionSummary>
                    <Box
                      sx={{
                        display: "flex",
                        width: "98%",
                        marginInline: "auto",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            alignItems: "center",
                            border: "2px solid #D8D5CD",
                            backgroundImage: `url('${audio.thumbnail}')`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        ></Box>
                        <Typography
                          sx={{
                            fontFamily: "var(--font-i_r)",
                            fontWeight: "500",
                            textTransform: "capitalize",
                            fontSize: "14px",
                            ml: 1,
                          }}
                        >
                          {audio.artist}
                        </Typography>
                      </Box>

                      <FaChevronDown
                        sx={{ fontSize: "0.9rem", color: "#fff" }}
                      />
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      className="no-scroll"
                      sx={{
                        width: "100%",
                        marginInline: "auto",
                        justifyContent: "center",
                        height: "40%",
                        overflowY: "scroll",
                      }}
                    >
                      {audio.audioList.map((audio, i) => {
                        return (
                          <Box
                            onClick={() => {
                              setProgress(0);
                              setPlaying(false);
                              setAudio({ ...audio, index: i });
                            }}
                            sx={{
                              display: "flex",
                              py: 1,
                              cursor: "pointer",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                width: "40%",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "var(--font-i_r)",
                                  fontSize: "0.8vw",
                                  mx: 3,
                                  color:
                                    selectedAudio.title === audio.title
                                      ? "#ffb800"
                                      : "#C4C4C4",
                                }}
                              >
                                0{i + 1}
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  width: "100%",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                    alignItems: "center",
                                    border: "1px solid #D8D5CD",
                                    backgroundImage: `url('${audio.thumbnail}')`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                  }}
                                ></Box>
                                <Box mx={1}>
                                  <Typography
                                    sx={{
                                      fontFamily: "var(--font-i_r)",
                                      fontSize: "0.6vw",
                                      textWrap: "nowrap",
                                      color:
                                        selectedAudio.title === audio.title
                                          ? "#ffb800"
                                          : "#C4C4C4",
                                    }}
                                  >
                                    {audio.title?.length > 20
                                      ? audio.title?.substring(0, 20) + "..."
                                      : audio.title}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontFamily: "var(--font-i_r)",
                                      fontSize: "14px",
                                      color:
                                        selectedAudio.title === audio.title
                                          ? "#ffb800"
                                          : "#C4C4C4",
                                    }}
                                  >
                                    {audio.artist}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>

                            <Typography
                              sx={{
                                fontFamily: "var(--font-i_r)",
                                fontSize: "0.8vw",
                                width: "20%",
                                textAlign: "center",
                                color:
                                  selectedAudio.title === audio.title
                                    ? "#ffb800"
                                    : "#C4C4C4",
                              }}
                            >
                              <AudioDurations audioUrl={audio.url} />
                            </Typography>
                            <FaSpotify
                              onClick={() =>
                                window.open(audio.spotify, "_blank")
                              }
                              color={
                                selectedAudio.title === audio.title
                                  ? "#ffb800"
                                  : "#1DB954"
                              }
                              size={20}
                            />
                            <FaPlay
                              color={
                                selectedAudio.title === audio.title
                                  ? "#ffb800"
                                  : "#C4C4C4"
                              }
                              size={20}
                            />
                            {loading && audioName === audio.title ? (
                              <CircularProgress color="primary" />
                            ) : (
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (audioRef.current) {
                                    audioRef.current = null;
                                  }
                                  handlePredefinedAudio(audio.url, audio.title);
                                }}
                              >
                                <IoCloudDownloadSharp
                                  color={
                                    selectedAudio.title === audio.title
                                      ? "#ffb800"
                                      : "#C4C4C4"
                                  }
                                  size={20}
                                />
                                <Typography
                                  sx={{
                                    fontFamily: "var(--font-g)",
                                    fontSize: "0.8vw",
                                    ml: 1,
                                    textAlign: "center",
                                    color:
                                      selectedAudio.title === audio.title
                                        ? "#ffb800"
                                        : "#C4C4C4",
                                  }}
                                >
                                  USE
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "92%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "46%", height: "1px", background: "#ffb800" }} />
            <Typography
              sx={{
                fontWeight: "600",
                fontFamily: "var(--font-g)",
                fontSize: "0.8vw",
                marginBlock: 1,
                textAlign: "center",
                color: "#C4C4C4",
              }}
            >
              OR
            </Typography>
            <Box sx={{ width: "46%", height: "1px", background: "#ffb800" }} />
          </Box>

          <Typography
            sx={{
              fontWeight: "600",
              fontFamily: "var(--font-g)",
              fontSize: "0.6vw",
              marginBlock: 1,
              textAlign: "center",
              color: "#C4C4C4",
            }}
          >
            Upload Your Audio To Bored Studio
          </Typography>
          <Box
            sx={{
              display: "flex",
              // width: "250px",
              borderRadius: "8px",
              border: `1px solid  #E3DFD6`,
              alignItems: "center",
              justifyContent: "center",
              mb: 2,
            }}
          >
            {["MP3", "M4A", "WAV"].map((item, i) => {
              return (
                <Typography
                  key={i + item}
                  sx={{
                    fontWeight: "600",
                    paddingBlock: "10px",
                    fontFamily: "var(--font-g)",
                    fontSize: "0.8vw",
                    marginInline: ".9vw",
                    color: "#E3DFD6",
                  }}
                >
                  {item}
                </Typography>
              );
            })}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "92%",
              marginInline: "auto",
              borderRadius: "0.5vw",
              backgroundColor: "#242424",
              cursor: "pointer",
              alignItems: "center",
              justifyContent: "center",
              paddingBlock: 1,
              margin: 1,
            }}
            onClick={() => {
              if (audioRef.current) {
                audioRef.current.pause();
              }
              onUpload();
            }}
          >
            <img
              src={STORAGE_URL + "Upload%20icon.png"}
              style={{ width: "30px" }}
              alt="upload audio file"
            />

            <Typography
              sx={{
                fontWeight: "600",
                fontFamily: "var(--font-i_r)",
                fontSize: "0.7vw",
                marginBlock: "0.5vw",
                color: "#C4C4C4",
              }}
            >
              Select your audio file.
            </Typography>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export const UploadStickerDialog = ({ open, setOpen, onUpload }) => {
  const isMobile = useMediaQuery("(max-width:599px)");
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        disableEnforceFocus
        slotProps={{
          backdrop: {
            style: {
              zIndex: 2,
              cursor: "pointer",
              backgroundColor: "#000",
              opacity: "0.7",
            },
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: "#000",
            zIndex: 10,
            height: "40%",
            border: `1px solid #ffb800`,
            borderRadius: "20px",
          },
        }}
        onClose={() => setOpen(false)}
        open={open}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            mx: "auto",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "var(--font-g)",
              fontSize: isMobile ? "18px" : "1.2vw",
              fontWeight: 600,
              my: 1,
              color: "#fff",
            }}
          >
            UPLOAD YOUR STICKER
          </Typography>
          <Box>
            <Typography
              sx={{
                fontFamily: "var(--font-g)",
                fontSize: isMobile ? "10px" : "0.8vw",
                fontWeight: 300,
                textAlign: "left",
                display: "flex",
                alignItems: "center",
                color: "#C4C4C4",
              }}
            >
              <Box
                sx={{
                  height: "10px",
                  width: "10px",
                  marginRight: "5px",
                  background: "#ffb800",
                  borderRadius: "50%",
                }}
              />
              Sticker can be any image with a transparent background
            </Typography>
            <Typography
              sx={{
                fontFamily: "var(--font-g)",
                fontSize: isMobile ? "10px" : "0.8vw",
                fontWeight: 300,
                my: 1,
                textAlign: "left",
                display: "flex",
                alignItems: "center",
                color: "#C4C4C4",
              }}
            >
              <Box
                sx={{
                  height: "10px",
                  width: "10px",
                  marginRight: "5px",
                  background: "#ffb800",
                  borderRadius: "50%",
                }}
              />
              Sticker must be 1024x1024px
            </Typography>
            <Typography
              sx={{
                fontFamily: "var(--font-g)",
                fontSize: isMobile ? "10px" : "0.8vw",
                fontWeight: 300,
                display: "flex",
                alignItems: "center",
                textAlign: "left",
                mb: 4,
                color: "#C4C4C4",
              }}
            >
              <Box
                sx={{
                  height: "10px",
                  width: "10px",
                  marginRight: "5px",
                  background: "#ffb800",
                  borderRadius: "50%",
                }}
              />
              Only PNGs are allowed
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "92%",
              marginInline: "auto",
              borderRadius: "0.5vw",
              backgroundColor: "#242424",
              cursor: "pointer",
              alignItems: "center",
              justifyContent: "center",
              paddingBlock: 1,
              margin: 1,
            }}
            onClick={() => {
              onUpload();
              setOpen(false);
            }}
          >
            <img
              src={STORAGE_URL + "Upload%20icon.png"}
              style={{ width: "30px" }}
              alt="upload audio file"
            />

            <Typography
              sx={{
                fontWeight: "600",
                fontFamily: "var(--font-i_r)",
                fontSize: isMobile ? "12px" : "0.7vw",
                marginBlock: "0.5vw",
                color: "#C4C4C4",
              }}
            >
              Select your Sticker
            </Typography>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};
const AudioDurations = ({ audioUrl }) => {
  const [durations, setDurations] = useState([]);

  useEffect(() => {
    const getDurations = async () => {
      return new Promise((resolve) => {
        const audio = new Audio(audioUrl);
        audio.addEventListener("loadedmetadata", () => {
          setDurations(audio.duration);
        });
      });
    };

    getDurations();
  }, [audioUrl]);

  return (
    <div>{`${Math.floor(durations / 60)}:${Math.floor(durations % 60)
      .toString()
      .padStart(2, "0")}`}</div>
  );
};
