import {
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { BsJustifyLeft, BsJustifyRight, BsShare } from "react-icons/bs";
import { SlSizeFullscreen } from "react-icons/sl";

import { GiShadowFollower } from "react-icons/gi";
import "../../../components/Loader.css";
import "../../../components/buttons/styles.css";

import axios from "axios";
import { toPng } from "html-to-image";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Unity } from "react-unity-webgl";
import Axioss from "../../../services/axios";
import {
  setModelEAELoaded,
  setModelExpressions,
  setModelProductLoaded,
  setRecording,
} from "../../../slice/3dModel.slice";
import {
  allEyes,
  allFaces,
  BASEURL,
  cleanJsonValues,
  exitFullscreen,
  GetTouchDevices,
  GPUChecker,
  handleFullscreen,
  htmlToImageConvert,
  rgba2hex,
  startRecording,
} from "../../../utils";

import { enqueueSnackbar } from "notistack";
import ColorPicker from "react-best-gradient-color-picker";
import { BiAlignJustify } from "react-icons/bi";
import {
  FaBold,
  FaCaretDown,
  FaChevronDown,
  FaFont,
  FaItalic,
} from "react-icons/fa";
import {
  MdModeEditOutline,
  MdOutlinePause,
  MdOutlinePauseCircleFilled,
  MdOutlinePlayArrow,
  MdOutlinePlayCircleFilled,
  MdOutlineQuestionMark,
  MdOutlineStopCircle,
} from "react-icons/md";
import { PiMusicNoteLight, PiTextItalic } from "react-icons/pi";
import { RxCross2 } from "react-icons/rx";
import { IconContainer, infoItems } from ".";
import { MBALogo } from "../../../assets/images";
import { ReactComponent as AR } from "../../../assets/images/icons/AR.svg";
import Layout from "../../../pages/pricing/payment/Layout";
import UnityComponent from "../../3dModels/ARUnity/UnityComponent";
import { ConvertingVideoModal } from "../../ConvertingVideoModal/ConvertingVideoModal";
import { ShareDialog, UploadDialog } from "../../ShareDialog/ShareDialog";
import ModelTool from "./ModelTool";
import ModelToolAudio from "./ModelToolAudio";
import { useTheme } from "@emotion/react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 600,
  display: "flex",
  zIndex: 10000,
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
  bgcolor: "#1c1c1c",
  boxShadow: 24,
  p: 1,
};

export function ApeUnity({
  mode,
  unity,
  tabMode,
  modelJson,
  setModelJson,
  selectedScene = null,
}) {
  const { id, nftId } = useParams();
  const { palette, ...theme } = useTheme();

  const dispatch = useDispatch();
  const [shaders, setShaders] = useState(null);
  const [openShare, setOpenShare] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);

  const [duration, setDuration] = useState(10);
  const env = useSelector((e) => e?.model?.env)?.find((e) => e.selected) || [];
  const environment = env[Object.keys(env)[0]]?.find((e) => e.selected)?.name;
  const mba = useSelector((e) => e?.model?.mba)?.find((e) => e.selected) || [];

  const mbaData = mba[Object.keys(mba)[0]]?.find((e) => e.selected);
  const [multiPose, setMultiPose] = useState(null);

  const multiPoseIds = [
    71, 73, 77, 78, 126, 124, 103, 123, 111, 110, 109, 107, 106,
  ];
  const multiPoseIds2 = [8, 26, 27, 29, 48, 64, 70, 68];
  const recording = useSelector((e) => e?.model?.recording);
  const animati =
    useSelector((e) => e?.model?.animations)?.find((e) => e.selected) || [];
  const expre =
    useSelector((e) => e?.model?.expressions)?.find((e) => e.selected) || [];
  const exp = useSelector((e) => e?.model?.expressions);
  const expression = expre[Object.keys(expre)[0]]?.find(
    (e) => e.selected
  )?.name;
  const animation = animati[Object.keys(animati)[0]]?.find((e) => e.selected);
  const poses =
    useSelector((e) => e?.model?.pose)?.find((e) => e.selected) || [];
  const pose = poses[Object.keys(poses)[0]]?.find((e) => e.selected);
  const [loading, setLoading] = useState(false);
  const [unityModelLoaded, setUnityModelLoaded] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [pause, setPause] = useState(false);
  const [mbaDataImage, setMbaDataImage] = useState(null);

  const [fullScreen, setFullScreen] = useState(false);
  const [previewContent, setPreviewContent] = useState(new Blob());
  const [dialogModal, setDialogModal] = useState(false);
  const [converting, setConverting] = useState(false);

  const [shareText, setShareText] = useState(null);
  const [memeText, setMemeText] = useState("");
  const [openEditText, setOpenEditText] = useState(false);
  const [showColorSelector, setShowColorSelector] = useState(false);
  const [downloadType, setDownloadType] = useState("HighRes");

  const inputRef = React.useRef(null);
  const dropdownRef = React.useRef(null);
  const [audioSrc, setAudioSrc] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = React.useRef(null);
  const fileInputRef = React.useRef(null);
  const [audioName, setAudioName] = useState("Audio");

  const [font, setFont] = useState({
    color: "#000000",
    size: 10,
    just: "center",
    family: "LegacyRuntime",
    type: "normal",
  });
  const [showAR, setShowAR] = useState(false);
  useEffect(() => {
    fetch("/baycMeta.json")
      .then((response) => {
        return response.json();
      })
      .then((jsonData) => {
        getModelJson(jsonData);
      })
      .catch((error) => console.error("Error fetching JSON:", error));
  }, []);
  const getModelJson = async (metaArray) => {
    const selectedJson = await metaArray.find((element) => element.ID == nftId);

    const cleanedObject = cleanJsonValues(selectedJson);
    let expType = "";
    let mouthType = "";
    for (let key in allEyes) {
      allEyes[key].find((e, i) => {
        if (e === cleanedObject["Eyes"]) {
          expType = key;
          return;
        }
      });
    }
    for (let key in allFaces) {
      allFaces[key].find((e, i) => {
        if (e === cleanedObject["Mouth"]) {
          mouthType = key;
          return;
        }
      });
    }
    const updatedAnimationPose = exp.map((pose, i) => {
      const key = Object.keys(pose)[0];
      console.log(key);
      if (key === "eyes") {
        return {
          [key]: exp[0][Object.keys(exp[0])[0]].map((it) => {
            if (it.name === expType) {
              return {
                ...it,
                selected: true,
              };
            }
            return {
              ...it,
              selected: false,
            };
          }),
          selected: true,
        };
      }
      if (key === "mouth") {
        return {
          [key]: exp[1][Object.keys(exp[1])[0]].map((it) => {
            if (it.name === mouthType) {
              return {
                ...it,
                selected: true,
              };
            }
            return {
              ...it,
              selected: false,
            };
          }),
          selected: false,
        };
      }
      return {
        [key]: pose[Object.keys(pose)[0]].map((it) => {
          return {
            ...it,
            selected: false,
          };
        }),
        selected: false,
      };
    });
    dispatch(setModelExpressions(updatedAnimationPose));
    if (cleanedObject) {
      delete cleanedObject.ID;
      if (id === "mutant-ape-yacht-club") {
        setModelJson({
          Clothes: "",
          Earring: "",
          Eyes: "",
          Flipflop: "",
          Fur: "Murtis",
          Hat: "",
          Mouth: "",
          Unshaven: "",
          Head: "",
        });
      } else {
        setModelJson(cleanedObject);
      }
    } else {
      alert(`NFT with This id ${id} Not Found `);
      setModelJson({});
    }
  };
  const [pixelRatio, setPixelRatio] = React.useState(null);
  React.useEffect(() => {
    GPUChecker(setPixelRatio);
  }, []);
  const handleOpenAR = () => {
    setShowAR(true);
  };
  const handleCloseAR = () => {
    setShowAR(false);
  };

  const handleFontJustify = (just) => {
    setFont({
      ...font,
      just,
    });
  };
  const handleFontType = (type) => {
    setFont({
      ...font,
      type,
    });
  };
  const handleAudioUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAudioName(file.name);
      setUploadOpen(false);
      const reader = new FileReader();
      reader.onload = (e) => {
        setAudioSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    if (unity?.addEventListener) {
      unity?.addEventListener("ModelLoadedReactEvent", (e) => {
        if (e) {
          setUnityModelLoaded(true);
        }
      });
      // unity?.addEventListener("StartAudio", (e) => {
      //   console.log("StartAudio", e);
      //   if (e) {
      //     setIsPlaying(true);
      //     setAudioSrc(selectedScene?.audio);
      //   }
      // });
      unity?.addEventListener("TraitsLoaded", (e) => {
        if (e) {
          setUnityModelLoaded(true);
        }
      });
      unity?.addEventListener("EnvironmentLoadedReactEvent", (e) => {
        dispatch(setModelEAELoaded(e ? true : false));
      });
      unity?.addEventListener("ProductLoadedReactEvent", (e) => {
        dispatch(setModelProductLoaded(e ? true : false));
      });
      return () => {
        unity?.removeEventListener("ModelLoadedReactEvent", () => {
          console.log('Received "OnController" event from Unity');
        });
        unity?.removeEventListener("EnvironmentLoadedReactEvent", () => {
          console.log('Received "OnController" event from Unity');
        });
        unity?.removeEventListener("TraitsLoaded", () => {
          console.log('Received "OnController" event from Unity');
        });
        unity?.removeEventListener("ProductLoadedReactEvent", () => {
          console.log('Received "OnController" event from Unity');
        });
      };
    }
  }, [unity?.addEventListener, unity?.removeEventListener, window]);

  useEffect(() => {
    if (unity?.isLoaded && unityModelLoaded && tabMode === "inspect") {
      unity?.sendMessage("Manager", "ChangeAnimations", "Pose#T_Pose");
      unity?.sendMessage("NFT Parent", "ResetCameraPosition");
      unity?.sendMessage("Manager", "EnableMouthTraits", "true");
      unity?.sendMessage("Manager", "EnableEyesTraits", "true");
      unity?.sendMessage("Manager", "ChangeMouth", "");

      for (let key in allEyes) {
        allEyes[key].find((e, i) => {
          if (e === modelJson?.Eyes) {
            unity?.sendMessage("Manager", "ChangeEyes", key);
          }
        });
      }
      for (let key in allFaces) {
        allFaces[key].find((e, i) => {
          if (e === modelJson?.Mouth) {
            unity?.sendMessage("Manager", "ChangeMouth", key);
          }
        });
      }
    }
  }, [unity?.isLoaded, unityModelLoaded, tabMode]);
  useEffect(() => {
    if (unity?.isLoaded && unityModelLoaded && mode === "Expressions") {
      if (Object.keys(expre)[0] === "eyes") {
        unity?.sendMessage("Manager", "ChangeEyes", expression);
        unity?.sendMessage("Manager", "EnableEyesTraits", "false");
      } else {
        unity?.sendMessage("Manager", "EnableMouthTraits", "false");
        console.log("Manager", "ChangeMouth", expression);
        unity?.sendMessage("Manager", "ChangeMouth", expression);
      }
    }
  }, [unity?.isLoaded, unityModelLoaded, expression, mode]);
  useEffect(() => {
    if (unity?.isLoaded && environment) {
      unity?.sendMessage("Manager", "EnableEnvironment", environment);
    }
  }, [unity?.isLoaded, environment, unityModelLoaded]);
  useEffect(() => {
    if (
      unity?.isLoaded &&
      unityModelLoaded &&
      shaders &&
      (mode === "MBA" ||
        (shaders === "DissolveShader" && memeTextArr.includes(pose?.name)))
    ) {
      unity?.sendMessage("Manager", "DefaultShader");
      setShaders("DefaultShader");
    }
  }, [unity?.isLoaded, shaders, unityModelLoaded, mode, pose]);
  useEffect(() => {
    if (
      unity?.isLoaded &&
      (id === "boredapeyachtclub" || id === "mutant-ape-yacht-club")
    ) {
      unity?.sendMessage(
        "Manager",
        "IsDesktop",
        GetTouchDevices() ? "false" : "true"
      );
      unity?.sendMessage("Manager", "IsDashBo");
      unity?.sendMessage(
        "Manager",
        "ReceiveJsonValues",
        JSON.stringify({
          ...modelJson,
        })
      );
    }
    // eslint-disable-next-line
  }, [unity?.isLoaded, modelJson]);

  const getMbaImage = async () => {
    const formData = new FormData();
    formData.append("url", mbaData?.image_url);
    const res = await fetch(`${BASEURL}files/getFile`, {
      method: "POST",
      body: formData,
    });
    const blob = await res.blob();
    const imgUrl = URL.createObjectURL(blob);
    setMbaDataImage(imgUrl);
  };
  useEffect(() => {
    if (
      unity?.isLoaded &&
      mbaData?.name &&
      unityModelLoaded &&
      mode === "MBA"
    ) {
      unity?.sendMessage(
        "Manager",
        "ChangeAnimations",
        id === "boredapeyachtclub" || id === "mutant-ape-yacht-club"
          ? mbaData?.type?.toLowerCase() + "#" + mbaData?.name
          : mbaData?.name
      );
      unity?.sendMessage("NFT Parent", "ResetCameraPosition");
    }
    unity?.sendMessage(
      "NFT Parent",
      "IsInspect",
      mode === "inspect" ? "true" : "false"
    );
    getMbaImage();
  }, [unity?.isLoaded, mbaData?.name, unityModelLoaded, mode]);

  useEffect(() => {
    if (unity?.isLoaded && unityModelLoaded) {
      unity?.sendMessage(
        "Manager",
        "ChangeTextProprties",
        JSON.stringify({
          justify: font.just,
          fontSize: font.size,
          color: font.color,
          fontStyle: font.type,
          font: font.family,
        })
      );
    }
  }, [unity?.isLoaded, unityModelLoaded, font]);
  useEffect(() => {
    if (unity?.isLoaded && unityModelLoaded) {
      unity?.sendMessage(
        "Manager",
        "UpdateText",
        memeText && memeText?.length > 0 ? memeText : ""
      );
    }
  }, [unity?.isLoaded, unityModelLoaded, memeText]);

  useEffect(() => {
    if (unity?.isLoaded && unityModelLoaded && shaders) {
      unity?.sendMessage(
        // String(nftId) + "(Clone)",
        id === "boredapeyachtclub" || id === "mutant-ape-yacht-club"
          ? "Manager"
          : "ShaderManager",
        shaders
      );
    }
  }, [unity?.isLoaded, shaders, unityModelLoaded]);

  useEffect(() => {
    if (unity?.isLoaded) {
      if (id !== "boredapeyachtclub" || id === "mutant-ape-yacht-club") {
        console.log("LoadAssets", nftId);
        unity?.sendMessage("Manager", "LoadAssets", nftId);
      }
    }
  }, [unity?.isLoaded, nftId]);
  useEffect(() => {
    if (unity?.isLoaded && unityModelLoaded) {
      if (mode === "animate") {
        unity?.sendMessage(
          "Manager",
          "ChangeAnimations",
          id === "boredapeyachtclub" || id === "mutant-ape-yacht-club"
            ? animation?.type?.toLowerCase() + "#" + animation?.name
            : animation?.name
        );
        return unity?.sendMessage("NFT Parent", "ResetCameraPosition");
      }
    }
  }, [unity?.isLoaded, animation, mode, unityModelLoaded, expre]);

  useEffect(() => {
    if (unity?.isLoaded && unityModelLoaded) {
      if (mode === "pose") {
        unity?.sendMessage("Manager", "UpdateText", "");
        unity?.sendMessage(
          "Manager",
          "ChangeAnimations",
          id === "boredapeyachtclub" || id === "mutant-ape-yacht-club"
            ? pose?.type?.toLowerCase() + "#" + pose?.name
            : pose?.name
        );
        unity?.sendMessage("NFT Parent", "ResetCameraPosition");
        setMemeText("");
        setFont({
          color: "#000000",
          size: 10,
          just: "center",
          family: "LegacyRuntime",
          type: "normal",
        });
      }
    }
  }, [unity?.isLoaded, , pose, mode, unityModelLoaded]);

  useEffect(() => {
    if (unity?.isLoaded && mode === "scenes") {
      unity?.sendMessage("Manager", pause ? "Pause" : "Resume");
    }
  }, [unity?.isLoaded, pause, mode]);

  const popupRef = React.useRef(null);
  const colorRef = React.useRef(null);
  const editorRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowInfo(false);
    }
  };

  useEffect(() => {
    if (showInfo) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showInfo]);

  const handleClickOutsideColor = (event) => {
    if (colorRef.current && !colorRef.current.contains(event.target)) {
      setShowColorSelector(false);
    }
  };

  useEffect(() => {
    if (showColorSelector) {
      document.addEventListener("mousedown", handleClickOutsideColor);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideColor);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideColor);
    };
  }, [showColorSelector]);

  const handleClickOutsideEditor = (event) => {
    if (editorRef.current && !editorRef.current.contains(event.target)) {
      setOpenEditText(false);
    }
  };

  useEffect(() => {
    if (openEditText && !showColorSelector && !anchorEl) {
      document.addEventListener("mousedown", handleClickOutsideEditor);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideEditor);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideEditor);
    };
  }, [openEditText, showColorSelector, anchorEl]);

  const handleShare = () => {
    setOpenShare(!openShare);
  };

  const elementRef = React.useRef(null);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );
    };
  }, []);
  const handleColorChange = (newColor) => {
    if (newColor.includes("linear")) {
      setFont({
        ...font,
        color: newColor,
      });
    } else {
      const hex = rgba2hex(newColor);
      setFont({
        ...font,
        color: hex,
      });
    }
  };
  const handleSelectShader = (shader) => {
    setShaders(shader);
  };
  useEffect(() => {
    if (inputRef.current && tabMode === "pose" && pose?.name === "Table") {
      inputRef.current.focus();
    }
  }, [mode, pose]);

  const handleTwitterShare = async () => {
    const token = localStorage.getItem("stringToken");
    const tokenSecret = localStorage.getItem("stringTokenSec");
    if (token && tokenSecret) {
      const formData = new FormData();
      formData.append("token", token);
      formData.append("tokenSecret", tokenSecret);
      formData.append("text", shareText);
      setLoading(true);
      if (mode === "animate") {
        formData.append("media", previewContent);
      } else {
        const dataUrl = await toPng(elementRef.current, { cacheBust: false });
        const resp = await fetch(dataUrl);
        const imgBlob = await resp.blob();
        formData.append("media", imgBlob);
      }

      const response = await axios.post(BASEURL + "twitter/post", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setLoading(false);
      setDialogModal(false);
      if (response?.data?.id) {
        enqueueSnackbar("Post Shared on Twitter", {
          variant: "success",
        });
      }
    } else {
      localStorage.setItem("previousRoute", window.location.href);
      navigate("/myAccounts");
    }
  };
  const onTwitterShare = async () => {
    const token = localStorage.getItem("stringToken");
    const tokenSecret = localStorage.getItem("stringTokenSec");
    try {
      if (token && tokenSecret) {
        setOpenShare(false);
        if (mode === "animate" || mode === "scenes") {
          startRecording(
            "videoAnimation",
            id + nftId,
            (e) => dispatch(setRecording(e)),
            "onlyRecord",
            async (e) => {
              setPreviewContent(e);
              setDialogModal(true);
            },
            10,
            audioRef,
            audioRef?.current ? true : false,
            (e) => {
              setConverting(e);
            },
            downloadType
          );
        } else {
          const dataUrl = await toPng(elementRef.current, {
            cacheBust: false,
          });
          const resp = await fetch(dataUrl);
          setPreviewContent(resp?.url);
          setDialogModal(true);
        }
      } else {
        localStorage.setItem("previousRoute", window.location.href);
        navigate("/myAccounts");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onFBShare = async () => {
    setLoading(true);
    const dataUrl = await toPng(elementRef.current, {
      cacheBust: false,
    });
    const resp = await fetch(dataUrl);
    const imgBlob = await resp.blob();
    const formData = new FormData();
    formData.append("image", imgBlob);
    const response = await Axioss.post(`generteArImg`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    setLoading(false);
    if (response.data?.imageUrl) {
      setOpenShare(false);
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          response.data?.imageUrl
        )}&picture=${encodeURIComponent(response.data?.imageUrl)}`,
        "_blank"
      );
    }
  };

  const onDownlaod = async (fileName, dur) => {
    if (mode === "animate" || mode === "scenes") {
      if (audioRef.current) {
        audioRef.current.play();
      }
      setOpenShare(false);
      startRecording(
        "videoAnimation",
        fileName ? fileName : id,
        (e) => dispatch(setRecording(e)),
        "",
        () => {},
        duration,
        audioRef,
        audioRef.current ? true : false,
        (e) => {
          setConverting(e);
        },
        downloadType
      );
    } else {
      setOpenShare(false);
      handleFullscreen(elementRef, setFullScreen);
      setTimeout(() => {
        htmlToImageConvert(fileName, elementRef, setFullScreen);
      }, 1000);
    }
  };

  const handleAudioIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    const audioElement = audioRef.current;

    const handlePlay = () => {
      setIsPlaying(true);
    };

    const handlePause = () => {
      setIsPlaying(false);
    };

    if (audioElement) {
      audioElement.addEventListener("play", handlePlay);
      audioElement.addEventListener("pause", handlePause);
    }

    return () => {
      if (audioElement) {
        audioElement.removeEventListener("play", handlePlay);
        audioElement.removeEventListener("pause", handlePause);
      }
    };
  }, [audioRef]);
  const memeTextArr = [
    "Table",
    "whiteboard_marker",
    "MeebitWhiteBoardNPen",
    "wood_board",
    "board_Paper",
    "SM_Welcome_Sign",
    "board",
  ];

  return (
    <Box width={"99%"}>
      <Layout
        show={showAR}
        onClose={handleCloseAR}
        open={handleOpenAR}
        dialogSize="sm"
        alignTitle="center"
      >
        <UnityComponent
          flag
          animation={
            mode === "animate" ? animation : mode === "pose" ? pose : mbaData
          }
        />
      </Layout>

      {converting && (
        <ConvertingVideoModal
          open={converting}
          duration={duration}
          downloadType={downloadType}
        />
      )}
      <input
        type="file"
        accept=".mp3,.wav,.aac,.flac,.ogg,.m4a"
        ref={fileInputRef}
        onChange={handleAudioUpload}
        style={{ display: "none" }}
      />
      <audio ref={audioRef} src={audioSrc} loop style={{ display: "none" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {mode === "MBA" && mbaData?.numberOfPoses > 0 && (
            <Box sx={{ marginLeft: "1vw" }}>
              <ModelTool
                icon={FaChevronDown}
                shaders={
                  multiPoseIds.includes(mbaData?.id)
                    ? Array(6)
                        .fill()
                        ?.map((e, i) => {
                          return {
                            key: "Pose " + Number(i + 1),
                            value: i + Number(1),
                          };
                        })
                    : Array(8)
                        .fill()
                        ?.map((e, i) => {
                          return {
                            key: "Pose " + Number(i + 1),
                            value: i + Number(1),
                          };
                        })
                }
                onSelectShader={(e) => {
                  setMultiPose(e);
                  unity?.sendMessage(
                    "Manager",
                    "ChangeMBAPose",
                    id === "boredapeyachtclub" || id === "mutant-ape-yacht-club"
                      ? JSON.stringify({
                          index: e,
                          category:
                            mbaData?.type.toLowerCase() === "beverages"
                              ? "Can"
                              : "Flag",
                        })
                      : mbaData?.name
                  );
                  unity?.sendMessage("NFT Parent", "ResetCameraPosition");
                }}
                name={multiPose ? "Pose " + multiPose : "Select Pose"}
              />
            </Box>
          )}
        </div>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginBlock: "1.5vh",
            marginInline: mode === "inspect" ? "1.5vh" : "",
          }}
        >
          {mode !== "inspect" && (
            <>
              <Box sx={{ marginLeft: "1vw" }}>
                <ModelToolAudio
                  icon={PiMusicNoteLight}
                  startIcon={
                    audioSrc
                      ? isPlaying
                        ? MdOutlinePauseCircleFilled
                        : MdOutlinePlayCircleFilled
                      : null
                  }
                  stopIcon={MdOutlineStopCircle}
                  name={
                    audioName.length > 30 ? audioName.slice(0, 29) : audioName
                  }
                  stopIconClick={() => {
                    audioRef.current.pause();
                    audioRef.current.currentTime = 0;
                  }}
                  startIconClick={() => {
                    isPlaying
                      ? audioRef.current.pause()
                      : audioRef.current.play();
                  }}
                  endIconClick={() => {
                    if (audioRef.current) {
                      audioRef.current.pause();
                    }
                    setUploadOpen(true);
                  }}
                  onClickAction={() => {
                    if (isPlaying && audioRef.current) {
                      audioRef.current.pause();
                    }
                    setUploadOpen(true);
                  }}
                />
              </Box>
              {(mode !== "scenes" || tabMode === "MBA") && (
                <Box sx={{ marginLeft: "1vw" }}>
                  <ModelTool
                    icon={GiShadowFollower}
                    shaders={[
                      { key: "Default Shader", value: "DefaultShader" },
                      { key: "Toon Shader", value: "ToonShader" },
                      { key: "Dissolve Shader", value: "DissolveShader" },
                      { key: "Holo Shader", value: "HoloShader" },
                      { key: "Ghost Shader", value: "GhostShader" },
                    ]}
                    onSelectShader={handleSelectShader}
                    name="Choose shader"
                  />
                </Box>
              )}
              <Box sx={{ marginLeft: "1vw" }}>
                <ModelTool
                  icon={BsShare}
                  onClickAction={handleShare}
                  name="Share"
                />
              </Box>
            </>
          )}
        </Box>
      </Box>

      {dialogModal && (
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "90%",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              sx={{ color: "#fff" }}
              component="h2"
            >
              Share on Twitter
            </Typography>
            <RxCross2
              style={{ cursor: "pointer" }}
              onClick={() => setDialogModal(false)}
              color={"#F85B03"}
              size={25}
            />
          </Box>

          {mode === "animate" ? (
            <video
              controls
              src={URL.createObjectURL(previewContent)}
              width="80%"
            />
          ) : (
            <img src={previewContent} width="80%" />
          )}
          <TextField
            variant="outlined"
            placeholder="Enter Post Description"
            fullWidth
            sx={{
              width: "90%",
              my: 2,
              color: "#fff",
              fontFamily: "var(--font-i_r)",
              "& .MuiInputBase-input": { color: "#fff" },
              "& .MuiInputLabel-root": { color: "#fff" },
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: palette.button.default,
              },
            }}
            value={shareText}
            onChange={(e) => {
              setShareText(e.target.value);
            }}
          />
          <Button
            sx={{ marginTop: "10px" }}
            variant="contained"
            color="secondary"
            onClick={handleTwitterShare}
          >
            {loading ? <CircularProgress size={24} /> : "Post"}
          </Button>
        </Box>
      )}
      {recording && (
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            top: 28,
            width: "25%",
            p: 1,
            ml: 1,
            background: "#222423",
            borderRadius: "0.5vw",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "60px",
              p: "6px",
              background: "#F90000",
              borderRadius: "0.3vw",
              mr: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="circle"></div>
            <Typography
              sx={{
                fontWeight: "400",
                fontFamily: "var(--font-i_r)",
                fontSize: "0.45vw",
                color: "#fff",
              }}
            >
              REC
            </Typography>
          </Box>
          <Typography
            sx={{
              fontWeight: "400",
              fontFamily: "var(--font-i_r)",
              fontSize: "0.45vw",
              width: "90%",
              opacity: "70%",
              color: "#fff",
            }}
          >
            Recording in progress: ({duration} seconds.)
          </Typography>
        </Box>
      )}
      <Box
        id="imgGenerator"
        // onDoubleClick={handleFullscreen}
        sx={{
          ml: 1,
          border: recording ? "4px solid red" : "",
        }}
      >
        <Box
          ref={elementRef}
          onDoubleClick={() => {
            fullScreen
              ? exitFullscreen(elementRef, setFullScreen)
              : handleFullscreen(elementRef, setFullScreen);
          }}
          sx={{
            width: fullScreen ? "100vw" : "100%",
            // height: "100%",
            height: fullScreen
              ? "100vh"
              : { xl: "67vh", lg: "66vh", md: "64vh" },
          }}
          id="videoAnimation"
        >
          {!unity?.isLoaded && (
            <div
              style={{
                height: "73%",
                width: "78%",
                position: "absolute",
                borderRadius: "0.8vw",
                // marginTop: "-60px",
                backgroundColor: "rgba(0, 0, 0, 0.85)",
                display: "flex",
                zIndex: 99999,
                alignItems: "center",
                justifyContent: "center",
                [theme.breakpoints.down("md")]: {
                  height: "45%",
                  width: "90%",
                },
                [theme.breakpoints.down("sm")]: {
                  height: "50%",
                  width: "87%",
                },
              }}
            >
              <div className="loader"></div>
            </div>
          )}
          {pixelRatio && (
            <Unity
              style={{
                width: "100%",
                height: unity?.isLoaded ? "100%" : "0px",
              }}
              unityProvider={unity?.unityProvider}
              devicePixelRatio={pixelRatio}
            />
          )}
          {tabMode === "MBA" && mbaData && (
            <Box
              sx={{
                position: "sticky",
                width: "100%",
                mt: "-7vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",

                  bottom: 70,
                  left: "19vw",
                  p: 2,

                  borderRadius: "10px",
                  width: "96%",
                  height: "60px",
                  background: "rgba(0,0,0,0.1)",
                  // backdropFilter: "blur(px)",
                }}
              >
                <Box display="flex" alignItems="center">
                  <img width="50px" id="mba-brand-image" src={mbaDataImage} />
                  <Typography
                    ml={1}
                    sx={{ fontSize: "0.6vw", fontWeight: 500 }}
                    color="#fff"
                  >
                    {mbaData?.brand}
                  </Typography>
                  <Typography ml={1} sx={{ fontSize: "0.8vw" }} color="#fff">
                    |
                  </Typography>
                  <Typography
                    ml={1}
                    sx={{ fontSize: "0.6vw", fontWeight: 500 }}
                    color="#fff"
                  >
                    {mbaData?.url}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <img width="30px" src={MBALogo} alt="pass" />
                  <Typography
                    ml={1}
                    sx={{ fontSize: "0.6vw", fontWeight: 500 }}
                    color="#fff"
                  >
                    License #{mbaData?.license}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {showInfo && (
        <Box
          ref={popupRef}
          sx={{
            position: "absolute",
            display: "flex",
            bottom: mode === "inspect" ? 130 : 100,
            right: mode === "inspect" ? "1vw" : "27vw",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "12px",
            background: "#212121",
            p: 2,
          }}
        >
          {infoItems.map((info) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mx: 2,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontFamily: "var(--font-i_r)",
                      fontSize: "0.6vw",
                      color: "#fff",
                    }}
                  >
                    {info.topContent}
                  </Typography>
                  <img src={info.img} />
                </Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontFamily: "var(--font-i_r)",
                    fontSize: "0.6vw",
                    color: "#fff",
                  }}
                >
                  {info.bottomContent}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}
      {tabMode === "pose" && memeTextArr.includes(pose?.name) && (
        <>
          <Box
            style={{
              display: "flex",
              position: "absolute",
              bottom: "26px",
              left: "18%",
              width: "35%",
              height: "50px",
              background: "#222423",
              borderRadius: "0.5vw",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <MdModeEditOutline
              size="30px"
              color="#ffb800"
              onClick={() => setOpenEditText(!openEditText)}
              style={{ marginInline: "6px", cursor: "pointer" }}
            />
            <input
              ref={inputRef}
              className="textarea"
              style={{
                border: "none",

                width: "90%",
                height: "50px",
                background: "#222423",
                color: "#fff",

                // input: { color: "#fff" },
              }}
              value={memeText}
              onChange={(e) => setMemeText(e.target.value)}
              placeholder="Meme Text"
            />
          </Box>
          {openEditText && (
            <Box
              ref={editorRef}
              style={{
                display: "flex",
                position: "absolute",
                bottom: "86px",
                left: "18%",
                width: "36%",
                height: "50px",
                background: "#222423",
                borderRadius: "0.5vw",
                alignItems: "center",
                paddingInline: "10px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {showColorSelector && (
                <div
                  ref={colorRef}
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    backgroundColor: "white",
                    bottom: "60px",
                    left: "50px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ColorPicker
                    value={font.color}
                    hidePresets
                    hideInputs
                    hideControls
                    hideOpacity
                    onChange={(e) => handleColorChange(e)}
                  />
                </div>
              )}
              <Box
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "40px",
                  display: "flex",
                  // paddingBlock: "5px",
                  paddingInline: "10px",
                  fontSize: "0.8vw",
                  color: "#000",
                  width: "20%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  onClick={() => {
                    if (font.size > 2)
                      return setFont({
                        ...font,
                        size: font.size - 1,
                      });
                  }}
                  sx={{
                    userSelect: "none",
                    fontSize: "1.2vw",
                    cursor: "pointer",
                  }}
                >
                  -
                </Typography>
                <Typography>{font.size}</Typography>
                <Typography
                  onClick={() => {
                    setFont({
                      ...font,
                      size: font.size + 1,
                    });
                  }}
                  sx={{
                    userSelect: "none",
                    fontSize: "1.2vw",
                    cursor: "pointer",
                  }}
                >
                  +
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  width: "20%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  setShowColorSelector(!showColorSelector);
                }}
              >
                <Box
                  sx={{
                    height: "30px",
                    width: "30px",
                    borderRadius: "8px",
                    marginLeft: "4px",
                    backgroundColor: font.color,
                  }}
                />
                <FaCaretDown color="#fff" size={30} />
              </Box>
              <Box
                style={{
                  display: "flex",
                  width: "60%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <HoveredIconContainer
                  selected={font.type === "normal"}
                  Icon={FaFont}
                  onClick={() => handleFontType("normal")}
                />
                <HoveredIconContainer
                  selected={font.type === "bold"}
                  Icon={FaBold}
                  onClick={() => handleFontType("bold")}
                />
                <HoveredIconContainer
                  selected={font.type === "italic"}
                  Icon={PiTextItalic}
                  onClick={() => handleFontType("italic")}
                />
                <HoveredIconContainer
                  selected={font.type === "bolditalic"}
                  Icon={FaItalic}
                  onClick={() => handleFontType("bolditalic")}
                />

                <HoveredIconContainer
                  selected={font.just === "left"}
                  Icon={BsJustifyLeft}
                  onClick={() => handleFontJustify("left")}
                />
                <HoveredIconContainer
                  selected={font.just === "center"}
                  Icon={BiAlignJustify}
                  onClick={() => handleFontJustify("center")}
                />
                <HoveredIconContainer
                  selected={font.just === "right"}
                  Icon={BsJustifyRight}
                  onClick={() => handleFontJustify("right")}
                />
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup={"true"}
                  aria-expanded={open ? "true" : "false"}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  variant="outlined"
                  sx={{ textTransform: "capitalize" }}
                >
                  {font.family}
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  dense
                >
                  {[
                    {
                      key: "F37",
                      value: "F37",
                    },
                    {
                      key: "Legacy Runtime",
                      value: "LegacyRuntime",
                    },
                  ].map((shader, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        setFont({
                          ...font,
                          family: shader.value,
                        });
                        setAnchorEl(null);
                      }}
                      sx={{
                        fontFamily: "var(--font-i_r)",
                        textTransform: "capitalize",

                        "&:hover": {
                          backgroundColor: "#FFB800 !important", // Change background color on hover
                          color: "#000 !important",
                        },
                        "&.Mui-selected": {
                          backgroundColor: "rgba(0,0,0,0.8)", // Change background color when selected
                        },
                      }}
                    >
                      {shader.key}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Box>
          )}
        </>
      )}

      <Box
        sx={{
          display: "flex",
          pt: 1,
          justifyContent: "flex-end",
          alignItems: "center",
          marginInline: mode === "inspect" ? "1.3vh" : "",
          paddingBottom: "1.5vh",
        }}
      >
        {mode === "scenes" && (
          <IconContainer
            icon={pause ? MdOutlinePlayArrow : MdOutlinePause}
            onClick={() => {
              setPause(!pause);
            }}
          />
        )}
        {mode !== "scenes" && <IconContainer icon={AR} onClick={setShowAR} />}
        <IconContainer
          icon={SlSizeFullscreen}
          onClick={() => handleFullscreen(elementRef, setFullScreen)}
        />
        <IconContainer
          icon={MdOutlineQuestionMark}
          onClick={() => {
            setShowInfo(!showInfo);
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          position: "absolute",
          bottom: 50,
          width: "50%",
          justifyContent: "center",
        }}
      >
        <UploadDialog
          open={uploadOpen}
          setOpen={setUploadOpen}
          setAudioName={setAudioName}
          audioName={audioName}
          setAudioSrc={setAudioSrc}
          onUpload={() => {
            if (audioRef.current) {
              audioRef.current.pause();
            }
            handleAudioIconClick();
          }}
        />

        <ShareDialog
          mode={mode}
          duration={duration}
          setDuration={setDuration}
          setDownloadType={setDownloadType}
          downloadType={downloadType}
          openShare={openShare}
          setOpenShare={setOpenShare}
          onTwitterShare={onTwitterShare}
          onFBShare={onFBShare}
          onDownlaod={(e, a) => onDownlaod(e, a)}
        />
      </Box>
    </Box>
  );
}

export const HoveredIconContainer = ({ Icon, onClick, selected }) => {
  return (
    <Box
      sx={{
        cursor: "pointer",
        boxShadow: selected ? "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" : "",
        borderRadius: "6px",
        p: 1,
      }}
      onClick={onClick}
    >
      <Icon size={22} color="#fff" />
    </Box>
  );
};
