import { Avatar, Box, Grid, Typography } from "@mui/material";

import "../../../components/Loader.css";
import "../../../components/buttons/styles.css";

import * as React from "react";
import { useState } from "react";
import { useUnityContext } from "react-unity-webgl";

import { FaChevronLeft } from "react-icons/fa";
import { Accordion, AccordionDetails, AccordionSummary } from "../../Accordion";
import { ProjectButton } from "./ProjectButton";
import { ApeUnity } from "./UnityModel";
import TooltipCustom from "./ToolTip";

export const UnityUIScenes = ({ mode }) => {
  const [modelJson, setModelJson] = useState({});
  const storageUrl =
    "https://7ef40650-26ac-4365-9a53-56ef81e33968.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/0f0e5439-75e7-4ace-82cc-eaeb61a097a1/release_by_badge/latest/entry_by_path/content/?path=DashBoScenes";
  const unity = useUnityContext({
    //   ...unityBuildUrl,
    loaderUrl: storageUrl + ".loader.js",
    frameworkUrl: storageUrl + ".framework.js.unityweb",
    dataUrl: storageUrl + ".data.unityweb",
    codeUrl: storageUrl + ".wasm.unityweb",
    streamingAssetsUrl:
      "https://7ef40650-26ac-4365-9a53-56ef81e33968.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/0f0e5439-75e7-4ace-82cc-eaeb61a097a1/release_by_badge/latest/entry_by_path/content/?path=StreamingAssets",
    // loaderUrl: "/webgl/DashBoScenes.loader.js",
    // frameworkUrl: "/webgl/DashBoScenes.framework.js.unityweb",
    // dataUrl: "/webgl/DashBoScenes.data.unityweb",
    // codeUrl: "/webgl/DashBoScenes.wasm.unityweb",
    // streamingAssetsUrl: "/webgl/StreamingAssets",
    // cacheControl: handleCacheControl,

    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  const [modeType, setModeType] = useState("Animations");

  const RenderItems = ({ setSelectedScene }) => {
    const [expanded, setExpanded] = React.useState("");
    const [traitsLoaded, setTraitsLoaded] = React.useState(true);

    const handleChange = (panel) => (event, newExpanded) => {
      setRenderInsideIcon({ enable: false, i: null });
      setExpanded(newExpanded ? panel : false);
    };
    const [renderItems, setRenderItems] = useState([
      {
        Movies: [
          {
            name: "Neeson",
            audio:
              "https://storage.googleapis.com/3d-container/scenes/audios/Saxophone.WAV",
            image_url:
              "https://storage.googleapis.com/3d-container/scenes/gifs/Neeson.gif",
            selected: false,
          },
        ],
      },
      {
        Scenes: [
          {
            name: "SaxoApe",
            audio:
              "https://storage.googleapis.com/3d-container/scenes/audios/Saxophone.WAV",
            image_url:
              "https://storage.googleapis.com/3d-container/scenes/gifs/BAYC_POSE_sax.gif",
            selected: true,
          },
        ],
      },
      {
        Memes: [
          {
            name: "DicaprioHereIam",
            audio:
              "https://storage.googleapis.com/3d-container/scenes/audios/SofaSync.mp3",
            image_url:
              "https://storage.googleapis.com/3d-container/scenes/gifs/BAYC_ANM_soffa_dring.gif",
            selected: false,
          },
          {
            name: "HeresJohnny",
            audio:
              "https://storage.googleapis.com/3d-container/scenes/audios/Johny.wav",
            image_url:
              "https://storage.googleapis.com/3d-container/scenes/gifs/BAYC_POSE_Jhoney_4.gif",
            selected: false,
          },
        ],
      },
    ]);
    const [unityModelLoaded, setUnityModelLoaded] = useState(false);

    React.useEffect(() => {
      if (unity?.addEventListener) {
        unity?.addEventListener("AssetsLoaded", (e) => {
          setTraitsLoaded(e ? true : false);
        });
        unity?.addEventListener("TraitsLoaded", (e) => {
          if (e) {
            setUnityModelLoaded(true);
          }
        });
        return () => {
          unity?.removeEventListener("AssetsLoaded", () => {
            console.log('Received "OnController" event from Unity');
          });
          unity?.removeEventListener("TraitsLoaded", () => {
            console.log('Received "OnController" event from Unity');
          });
        };
      }
    }, [unity?.addEventListener, unity?.removeEventListener, window]);

    const [renderInsideIcon, setRenderInsideIcon] = useState({
      enable: false,
      i: null,
    });
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            overflowY: "scroll",
            height: "100%",
            width: "100%",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "100%" }}>
            {renderItems.map((acor, index) => {
              const renderToMap = renderInsideIcon.enable
                ? acor[Object.keys(acor)[0]][renderInsideIcon.i]?.items || []
                : acor[Object.keys(acor)[0]];
              return (
                <Accordion
                  key={index}
                  expanded={expanded === Object.keys(acor)[0]}
                  onChange={handleChange(Object.keys(acor)[0])}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography
                      sx={{
                        fontFamily: "var(--font-f_r)",
                        fontWeight: "700",
                        textTransform: "capitalize",
                        fontSize: { xl: "1.5em", lg: "1.2em", md: "1em" },
                      }}
                    >
                      {Object.keys(acor)[0]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {renderToMap.map((item, i) => {
                        return (
                          <Box
                            key={i}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              if (!traitsLoaded) return;
                              setSelectedScene(item);
                            }}
                            sx={{
                              cursor: "pointer",
                              border: "1px solid #474747",
                              padding: "15px",
                              margin: "10px",
                              borderRadius: "0.8vw",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              "&:hover": {
                                border: "1px solid #FFB800",
                              },
                            }}
                          >
                            {" "}
                            <TooltipCustom>
                              <Avatar
                                sx={{
                                  width: {
                                    xs: "16vw",
                                    lg: "5vw",
                                    xl: "5vw",
                                    sm: "25vw",
                                  },
                                  height: {
                                    xs: "16vw",
                                    lg: "5vw",
                                    xl: "5vw",
                                    sm: "25vw",
                                  },
                                  borderRadius: "0.8vw",
                                }}
                                variant="square"
                                src={item.image_url}
                                alt="model animation and poses"
                              />
                            </TooltipCustom>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {item.displayName && (
                                <Typography
                                  sx={{
                                    fontFamily: "var(--font-i_r)",
                                    textAlign: "center",
                                    color: "#E3DFD6",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    fontSize: {
                                      xs: "1.5vw",
                                      sm: "1.25vw",
                                      md: "0.8vw",
                                      lg: "0.6vw",
                                      xl: "0.5vw",
                                    },
                                    maxWidth: "10vw", // Adjust this value to ensure the text wraps as needed
                                    wordBreak: "break-word",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  {item.displayName}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        </Box>
      </>
    );
  };
  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      <Grid
        item
        xs={12}
        md={8}
        lg={8}
        alignItems="center"
        justifyContent="center"
      >
        <Box sx={{ minHeight: "75vh" }}>
          <ApeUnity
            setModelJson={setModelJson}
            modelJson={modelJson}
            unity={unity}
            mode={modeType === "Expressions" ? "Expressions" : mode}
            tabMode={mode}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={4} lg={4} paddingRight={{ xl: 2, lg: 1 }}>
        <Box
          sx={{
            width: "100%",
            height: "75vh",
            borderRadius: "0.5vw",
            display: "flex",
            flexDirection: "column",
            border: "1px solid #FFB800",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              paddingBlock: "18px",
              justifyContent: "center",
            }}
          >
            <ProjectButton
              project={"Animations"}
              setProject={setModeType}
              modeType={modeType}
            />
            <ProjectButton
              project={"Stills"}
              setProject={setModeType}
              modeType={modeType}
            />
          </Box>
          <RenderItems
            setSelectedScene={(e) =>
              unity?.sendMessage("Manager", "LoadScene", e?.name)
            }
          />
        </Box>
      </Grid>
    </Grid>
  );
};
