import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Tooltip, Typography, useMediaQuery } from "@mui/material";

const TooltipCustom = ({ children }) => {
  return (
    <> {children}</>
    // <Tooltip
    //   componentsProps={{
    //     tooltip: {
    //       sx: {
    //         bgcolor: "#1E1E1E",
    //         borderRadius: "10px",
    //         p: 1.5,
    //         "& .MuiTooltip-arrow": {
    //           color: "#1E1E1E",
    //         },
    //       },
    //     },
    //   }}
    //   arrow
    //   title={
    //     <Box>
    //       <Typography
    //         sx={{
    //           fontFamily: "var(--font-i_r)",
    //           textAlign: "left",
    //           color: "#ffb800",
    //           fontSize: {
    //             xs: "12px",
    //             sm: "14px",
    //             md: "14px",
    //             lg: "1vw",
    //             xl: "1vw",
    //           },
    //         }}
    //       >
    //         Confirm Purchase
    //       </Typography>
    //       <Typography
    //         sx={{
    //           fontFamily: "var(--font-i_r)",
    //           textAlign: "left",
    //           color: "#fff",
    //           mt: 1,
    //           fontSize: {
    //             xs: "10px",
    //             sm: "12px",
    //             md: "12px",
    //             lg: "0.8vw",
    //             xl: "0.8vw",
    //           },
    //         }}
    //       >
    //         Are you sure you want to purchase this Animation for 7 credits?
    //       </Typography>
    //       <Box
    //         sx={{
    //           mt: 1,
    //           display: "flex",
    //           justifyContent: "flex-end",
    //           alignItems: "flex-end",
    //         }}
    //       >
    //         <Button
    //           sx={{
    //             background: "#ffb800",
    //             color: "#000",
    //           }}
    //         >
    //           Confirm
    //         </Button>
    //       </Box>
    //     </Box>
    //   }
    // >
    //   {children}
    // </Tooltip>
  );
};

export default TooltipCustom;
