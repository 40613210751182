import React from "react";
import { Box, Skeleton } from "@mui/material";

const LoadingOverlay = ({ direc = "column" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: direc,
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 2,
        }}
      >
        <Skeleton
          variant="rectangular"
          width={160}
          height={160}
          sx={{
            marginRight: 2,
            cursor: "pointer",
            border: "1px solid #474747",
            padding: "15px",
            margin: "15px",
            borderRadius: "0.8vw",
          }}
        />
        <Skeleton
          variant="rectangular"
          width={160}
          height={160}
          sx={{
            marginRight: 2,
            cursor: "pointer",
            border: "1px solid #474747",
            padding: "15px",
            margin: "15px",
            borderRadius: "0.8vw",
          }}
        />
        <Skeleton
          variant="rectangular"
          width={160}
          height={160}
          sx={{
            marginRight: 2,
            cursor: "pointer",
            border: "1px solid #474747",
            padding: "15px",
            margin: "15px",
            borderRadius: "0.8vw",
          }}
        />
      </Box>

      {/* Second Row */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Skeleton
          variant="rectangular"
          width={160}
          height={160}
          sx={{
            marginRight: 2,
            cursor: "pointer",
            border: "1px solid #474747",
            padding: "15px",
            margin: "15px",
            borderRadius: "0.8vw",
          }}
        />
        <Skeleton
          variant="rectangular"
          width={160}
          height={160}
          sx={{
            marginRight: 2,
            cursor: "pointer",
            border: "1px solid #474747",
            padding: "15px",
            margin: "15px",
            borderRadius: "0.8vw",
          }}
        />
        <Skeleton
          variant="rectangular"
          width={160}
          height={160}
          sx={{
            marginRight: 2,
            cursor: "pointer",
            border: "1px solid #474747",
            padding: "15px",
            margin: "15px",
            borderRadius: "0.8vw",
          }}
        />
      </Box>
    </Box>
  );
};

export default LoadingOverlay;
