import { Box, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useState } from "react";

const ModelToolAudio = ({
  icon: Icon,
  stopIcon: StopIcon,
  shaders,
  stopIconClick,
  onClickAction,
  startIcon: StartIcon,
  name,
  startIconClick = null,
  endIconClick = null,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (!shaders) {
      onClickAction();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isMobile = useMediaQuery("(max-width:599px)");

  return (
    <Box>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup={shaders ? "true" : "false"}
        aria-expanded={open ? "true" : "false"}
        startIcon={
          StartIcon && (
            <>
              <StartIcon onClick={(e) => startIconClick && startIconClick(e)} />
              <StopIcon
                style={{ marginLeft: "6px" }}
                size={20}
                onClick={(e) => stopIconClick && stopIconClick(e)}
              />
            </>
          )
        }
        endIcon={<Icon onClick={(e) => endIconClick && endIconClick(e)} />}
        variant="outlined"
        sx={{ textTransform: "capitalize" }}
      >
        <span onClick={handleClick} style={{ display: isMobile ? "none" : "" }}>
          {name}
        </span>
      </Button>
    </Box>
  );
};

export default ModelToolAudio;
